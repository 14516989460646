import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './EditGoalPopup.css';
import { getGoals, createGoal } from '@/features/analytic';

type Goal = {
  id?: string;
  title: string;
  description?: string;
  department?: string;
  createdAt: string;
  goalType: 'binary' | 'non-binary';
  progress: number | string;
  company?: number;
  group?: number;
  parent?: string;
};

type EditGoalPopupProps = {
  goal: any;
  onSave: any;
  onClose: any;
  onDelete: any;
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const EditGoalPopup: React.FC<EditGoalPopupProps> = ({ goal, onSave, onClose, onDelete }) => {
  const [editedGoal, setEditedGoal] = useState<any>({
    ...goal,
    title: goal.title || '',
    description: goal.description || '',
    progress: goal.progress || 0,
    format: goal.format || 'percentage',
    goalType: goal.goalType || 2,
    group: goal.group || 3,
    company: goal.company || 1,
    parent: goal.parent || null,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditedGoal((prev:any) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    const goalToSave = {
      company: editedGoal.company,
      createdAt: editedGoal.createdAt,
      department: editedGoal.department,
      description: editedGoal.description,
      format: editedGoal.format,
      goalType: editedGoal.goalType === 'binary' ? 0 : 2, // Преобразуем в числовой формат
      group: editedGoal.group,
      id: editedGoal.id,
      parent: editedGoal.parent,
      progress: editedGoal.progress,
      title: editedGoal.title,
      updatedAt: new Date().toISOString(),
    };

    console.log(goalToSave);
    onSave(goalToSave);
    onClose();
  };

  const handleDelete = () => {
    if (onDelete && goal.id) {
      onDelete(goal.id);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="edit-goal-popup-overlay"
      onClick={onClose}
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -50, opacity: 0 }}
        className="edit-goal-popup"
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={onClose}
          className="edit-goal-popup-close-btn"
        >
          ×
        </button>
        
        <h3 className="edit-goal-popup-title">
          Редактирование цели
        </h3>

        <div className="edit-goal-popup-fields">
          <div>
            <label>Название цели</label>
            <input
              type="text"
              name="title"
              value={editedGoal.title}
              onChange={handleChange}
              className="edit-goal-popup-input"
            />
          </div>

          <div>
            <label>Описание (до 250 символов)</label>
            <textarea
              name="description"
              value={editedGoal.description}
              onChange={handleChange}
              maxLength={250}
              className="edit-goal-popup-textarea"
            />
          </div>

          <div>
            <label>Тип цели</label>
            <select
              name="goalType"
              value={editedGoal.goalType}
              onChange={handleChange}
              className="edit-goal-popup-select"
            >
              <option value="non-binary">Не бинарная</option>
              <option value="binary">Бинарная</option>
            </select>
          </div>

          <div>
            <label>Прогресс</label>
            {editedGoal.goalType === 'binary' ? (
              <select
                name="progress"
                value={editedGoal.progress}
                onChange={handleChange}
                className="edit-goal-popup-select"
              >
                <option value="Нет">Нет</option>
                <option value="Да">Да</option>
              </select>
            ) : (
              <input
                type="number"
                name="progress"
                value={editedGoal.progress}
                onChange={handleChange}
                min={0}
                max={100}
                className="edit-goal-popup-input"
              />
            )}
          </div>

          <div>
            <label>Ответственный отдел</label>
            <select
              name="department"
              value={editedGoal.department}
              onChange={handleChange}
              className="edit-goal-popup-select"
            >
              <option value="">Выберите отдел</option>
              <option value="Маркетинг">Маркетинг</option>
              <option value="Разработка">Разработка</option>
              <option value="Продажи">Продажи</option>
            </select>
          </div>

          <div>
            <label>Дата создания</label>
            <input
              type="text"
              value={formatDate(goal.createdAt)}
              disabled
              className="edit-goal-popup-input"
            />
          </div>
        </div>

        <div className="edit-goal-popup-buttons">
          <button
            onClick={onClose}
            className="edit-goal-popup-button edit-goal-popup-button-cancel"
          >
            Отмена
          </button>
          <button
            onClick={handleDelete}
            className="edit-goal-popup-button edit-goal-popup-button-delete"
          >
            Удалить
          </button>
          <button
            onClick={handleSave}
            className="edit-goal-popup-button edit-goal-popup-button-save"
          >
            Сохранить изменения
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default EditGoalPopup;