import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './EditGoalPopup.css';
import { getInterests } from '@/features/user';
import { getGoals, createGoal, updateGoal, deleteGoal } from '@/features/analytic';
import { getCompany } from '@/entities/company/api';

type Goal = {
  id?: number;
  title: string;
  description?: string;
  goalType: 'binary' | 'non-binary';
  progressType?: 'numeric' | 'percentage' | 'monetary';
  progress: number | string;
  currentValue?: number;
  targetValue?: number;
  parent?: number;
  company?: number;
  group?: number;
  archived?: boolean;
  target?: any,
  responsible?: any
};

type Department = {
  id: number;
  name: string;
  children: Team[];
};

type Team = {
  id: number;
  name: string;
  users: Employee[];
};

type Employee = {
  id: number;
  first_name: string;
  last_name: string;
  patronymic: string;
};

type EditGoalPopupProps = {
  goal: any;
  onSave: any;
  onClose: any;
  onDelete?: any;
  isNew: boolean;
  onEdit?: any;
};

const EditGoalPopup: React.FC<EditGoalPopupProps> = ({ goal, onSave, onClose, onDelete, isNew }) => {
  const [userCompany, setUserCompany] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const [selectedParentGoal, setSelectedParentGoal] = useState<number | null>(null);
  const [goals, setGoals] = useState<any[]>([]);
  const [userRole, setUserRole] = useState<'HR manager' | 'Super HR manager' | 'Manager' | 'User' | null>(null);
  const [assignToSelf, setAssignToSelf] = useState<boolean>(false);
  const [userUnitId, setUserUnitId] = useState<number | null>(null);
  
  const [isDepartmentManager, setIsDepartmentManager] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<number>(null);

  const companyId = JSON.parse(
    localStorage.getItem('user')
  )?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  const userId = JSON.parse(
    localStorage.getItem('user')
  )
  useEffect(() => {
    getCompany(companyId)
      .then((res: any) => {
        console.log(res.data);
        if (!res.data?.group) {
          console.error("Ошибка: Данные о компании не загружены.");
          return;
        }
        setUserCompany(res.data.group);

        // Находим отдел пользователя и устанавливаем userUnitId
        const storageUser = JSON.parse(localStorage.getItem('user') || '{}');
        res.data.group.forEach((dep: Department) => {
          if (dep.children) {
            dep.children.forEach((unit: Team) => {
              if (unit.users?.some((u: Employee) => u.id === storageUser.id)) {
                setUserUnitId(unit.id); // Устанавливаем userUnitId
              }
            });
          }
        });

        // Автоматически выбираем отдел для Manager
        if (storageUser?.role === 'Manager') {
          const managerDepartment = res.data.group.find((dep: Department) =>
            dep.children?.some((team: Team) =>
              team.users?.some((user: Employee) => user.id === storageUser.id)
            )
          );
          if (managerDepartment) {
            const managerTeam = managerDepartment.children.find((team: Team) =>
              team.users?.some((user: Employee) => user.id === storageUser.id)
            );
            if (managerTeam) {
              setSelectedTeam(managerTeam);
            }
          }
        }
      })
      .catch(e => {
        console.error("Ошибка при загрузке данных компании:", e);
      });
  }, [companyId]);
  const [editedGoal, setEditedGoal] = useState<Goal>({
    ...goal,
    description: goal.description || '',
    goalType: goal.goalType || 'non-binary',
    progressType: goal.progressType || 'percentage',
    progress: goal.progress || 0,
    currentValue: goal.currentValue || 0,
    targetValue: goal.targetValue || 0,
    parent: goal.parent || undefined,
    company: companyId,
    group: goal.group,
  });
  const [goalLevel, setGoalLevel] = useState<'department' | 'team' | 'employee'>(
    userRole === 'Manager' ? 'employee' : 'department'
  );
  useEffect(() => {
    const storageUser = JSON.parse(localStorage.getItem('user') || '{}');
    if (!storageUser?.id) {
      console.error("Ошибка: Пользователь не найден.");
      return;
    }
    if (storageUser?.role === 'HR manager') {
      setUserRole('HR manager');
    } else if (storageUser?.role === 'Super HR manager') {
      setUserRole('Super HR manager');
    } else if (storageUser?.role === 'User') {
      setUserRole('User');
      setGoalLevel('employee'); // Для User по умолчанию "сотрудник"
    } else if (storageUser?.role === 'Manager') {
      setUserRole('Manager');
      setGoalLevel('employee'); // Для Manager по умолчанию "сотрудник"
  
      // Автоматически выбираем отдел, в котором состоит Manager
      const managerDepartment = userCompany.find((dep: Department) =>
        dep.children?.some((team: Team) =>
          team.users?.some((user: Employee) => user.id === storageUser.id)
        )
      );
      if (managerDepartment) {
        const managerTeam = managerDepartment.children.find((team: Team) =>
          team.users?.some((user: Employee) => user.id === storageUser.id)
        );
        if (managerTeam) {
          setSelectedTeam(managerTeam);
        }
      }
    }
  }, [userCompany]);

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const response = await getGoals();
        const filteredGoals = response.data.filter((goal: any) => !goal.archived);
        setGoals(filteredGoals);
      } catch (error) {
        console.error('Ошибка при загрузке целей:', error);
      }
    };
    fetchGoals();
  }, []);
  useEffect(()=>{
    console.log(goals)
  },[goals])
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
  
    // Обработка статуса для бинарной цели
    if (name === "progress" && editedGoal.goalType === "binary") {
      const progressValue = value === "Выполнено" ? 100 : 0;
      setEditedGoal(prev => ({ ...prev, progress: progressValue }));
      return;
    }
  
    // Остальная логика обработки изменений
    if (name === "currentValue") {
      const numericValue = Number(value);
      setEditedGoal(prev => ({
        ...prev,
        currentValue: numericValue,
        progress: editedGoal.progressType === 'percentage'
          ? Math.round((numericValue / (prev.targetValue || 1)))
          : numericValue
      }));
      return;
    }
  
    if (name === "target") {
      const numericValue = Number(value);
      setEditedGoal(prev => ({
        ...prev,
        target: numericValue,
        progress: editedGoal.progressType === 'percentage'
          ? Math.round((prev.currentValue || 0) / numericValue * 100)
          : prev.progress
      }));
      return;
    }
  
    setEditedGoal(prev => ({ ...prev, [name]: value }));
  };
  const handleSave = async () => {
    try {
      // Общие проверки для всех типов целей
      if (
        (editedGoal.progressType === 'numeric' || editedGoal.progressType === 'monetary') &&
        (!editedGoal.target || editedGoal.target === 0)
      ) {
        alert("Поле 'Цель' обязательно для заполнения и не может быть равно 0.");
        return;
      }
  
      // Проверка обязательного поля "Привязать к" для уровня "сотрудник"
//       if (goalLevel === 'employee' && !selectedParentGoal) {
//         alert("Поле 'Привязать к' обязательно для заполнения.");
//         return;
//       }
  
      const goalTypeNumber = getGoalTypeNumber(editedGoal.goalType, editedGoal.progressType);
  
      const goalToSave: any = {
        title: editedGoal.title,
        description: editedGoal.description,
        goal_type: goalTypeNumber,
        progress: Number(editedGoal.progress),
        parent: selectedParentGoal || null,
        company: editedGoal.company,
        group: null,
        target: editedGoal.target || null,
        responsible: null,
      };
  
      // Обработка для разных ролей пользователей
      if (userRole === 'User') {
        const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
        
        // Проверка и установка отдела пользователя
        const userTeam = userCompany
          .flatMap(dep => dep.children)
          .find(team => 
            team.users?.some(user => user.id === currentUser.id)
          );
  
        if (!userTeam) {
          console.error("Ошибка: Отдел пользователя не найден.");
          return;
        }
  
        goalToSave.group = userTeam.id;
        goalToSave.responsible = currentUser.id;
  
      } else {
        // Обработка для HR и Manager
        switch (goalLevel) {
          case 'department':
            if (!selectedDepartment) {
              alert("Департамент не выбран.");
              return;
            }
            goalToSave.group = selectedDepartment.id;
            break;
  
          case 'team':
            if (!selectedTeam) {
              alert("Отдел не выбран.");
              return;
            }
            goalToSave.group = selectedTeam.id;
            break;
  
          case 'employee':
            if (!selectedTeam) {
              alert("Отдел не выбран.");
              return;
            }
            goalToSave.group = selectedTeam.id;
  
            // Назначение ответственного
            if (assignToSelf) {
              const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
              goalToSave.responsible = currentUser.id;
            } else if (selectedEmployee) {
              goalToSave.responsible = selectedEmployee.id;
            } else {
              alert("Сотрудник не выбран.");
              return;
            }
            break;
  
          default:
            alert("Неизвестный уровень цели.");
            return;
        }
      }

      console.log('Inside');

      // Дополнительные поля для процентных целей
      if (editedGoal.progressType === 'percentage') {
        goalToSave.currentValue = Number(editedGoal.currentValue);
        goalToSave.targetValue = Number(editedGoal.targetValue);
      }

      console.log("Данные для сохранения:", goalToSave);
      await onSave(goalToSave);
      onClose();
    } catch (error) {
      console.error("Ошибка при сохранении цели:", error);
      alert("Произошла ошибка при сохранении цели");
    }
  };
  const handleDelete = async () => {
    if (editedGoal.id) {
      await onDelete(editedGoal.id);
      onClose();
    }
  };

  const getGoalTypeNumber = (goalType: string, progressType?: string): number => {
    if (goalType === 'binary') return 0;
    if (progressType === 'numeric') return 1;
    if (progressType === 'percentage') return 2;
    if (progressType === 'monetary') return 3;
    return 2;
  };

  // Функция для получения инициалов сотрудника
  const getEmployeeInitials = (employee: Employee) => {
    return `${employee.last_name} ${employee.first_name[0]}.${employee.patronymic[0] ? employee.patronymic[0] : ''}.`;
  };
  const getDepartmentGoals = (goals: any[],departments:any): any[] => {
    return goals.filter((goal) => {
      // Цели уровня департамента не имеют parent и group соответствует id департамента
      return !goal.parent && departments.some((dep:any) => dep.id === goal.group);
    });
  };
  const getAvailableParentGoals = (goals: any[], departments: any[], selectedTeamId: number | null): any[] => {
    return goals.filter((goal) => {
      // Всегда исключаем архивные цели
      if (goal.archived) return false;
  
      // Если пользователь является сотрудником, фильтруем по userUnitId и responsible === null
      if (userRole === 'User' && userUnitId) {
        return goal.group === userUnitId && goal.responsible === null;
      }
  
      // Для других ролей используем существующую логику
      const isDepartmentGoal = !goal.responsible && departments.some(dep => dep.id === goal.group);
      const isTeamGoal = !goal.responsible && departments.some(dep => 
        dep.children?.some((team: any) => team.id === goal.group)
      );
  
      if (selectedTeamId) {
        return (isDepartmentGoal || isTeamGoal) && goal.group === selectedTeamId;
      }
  
      return isDepartmentGoal || isTeamGoal;
    });
  };

  useEffect(() => {
    getInterests(userId?.id)
    .then((response: any) => {
      if (response.status === 200) {
        const responseGroupId = response.data.group[0]
        setGroupId(responseGroupId);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }, [])

  useEffect(() => {
    checkIfDepartmentManager();
  }, [userCompany]);

  const checkIfDepartmentManager = () => {
    getInterests(userId?.id)
    .then((response: any) => {
      if (response.status === 200) {
        const departmentIds = userCompany.map((department: any) => department.id);
        const responseGroupId = response.data.group[0]
        setIsDepartmentManager(departmentIds.includes(responseGroupId) ? true : false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="edit-goal-popup-overlay"
      onClick={onClose}
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -50, opacity: 0 }}
        className="edit-goal-popup"
        onClick={e => e.stopPropagation()}
      >
        <button onClick={onClose} className="edit-goal-popup-close-btn">×</button>
  
        <h3 className="edit-goal-popup-title">
          {isNew ? 'Создание новой цели' : 'Редактирование цели'}
        </h3>
  
        <div className="edit-goal-popup-fields">
          {isNew && (
            <div>
              <label>Уровень цели</label>
              <select
                name="goalLevel"
                value={goalLevel}
                onChange={(e) => setGoalLevel(e.target.value as 'department' | 'team' | 'employee')}
                className="edit-goal-popup-select"
                disabled={userRole === 'User'}
              >
                {(userRole === 'HR manager' || userRole === 'Super HR manager' || isDepartmentManager) && (
                  <>
                    <option value="department">Департамент</option>
                    <option value="team">Отдел</option>
                    <option value="employee">Сотрудник</option>
                  </>
                )}
                {userRole === 'Manager' && !isDepartmentManager && (
                  <>
                    <option value="team">Отдел</option>
                    <option value="employee">Сотрудник</option>
                  </>
                )}
                {userRole === 'User' && (
                  <option value="employee">Сотрудник</option>
                )}
              </select>
            </div>
          )}
  
          {isNew && goalLevel === 'department' && (userRole !== 'Manager' || isDepartmentManager) && (
            <div>
              <label>Департамент</label>
              <select
                name="department"
                className="edit-goal-popup-select"
                onChange={(e) => {
                  const selected = userCompany.find(dep => dep.id === Number(e.target.value));
                  setSelectedDepartment(selected || null);
                }}
              >
                <option value="">Выберите департамент</option>
                {isDepartmentManager ?
                  userCompany.map((department: Department) => {
                    if (department.id === groupId) {
                      return <option key={department.id} value={department.id}>{department.name}</option>
                    }
                  })
                :
                  userCompany.map((department: Department) => (
                    <option key={department.id} value={department.id}>{department.name}</option>
                  ))
                }
              </select>
            </div>
          )}
  
          {/* Изменения для роли Manager: убрано поле выбора отдела для уровня "отдел" */}
          {isNew && goalLevel === 'team' && userRole !== 'Manager' && (
            <div>
              <label>Отдел</label>
              <select
                name="team"
                className="edit-goal-popup-select"
                onChange={(e) => {
                  const selected = userCompany
                    .flatMap(dep => dep.children)
                    .find(team => team.id === Number(e.target.value));
                  setSelectedTeam(selected || null);
                }}
              >
                <option value="">Выберите отдел</option>
                {userCompany.flatMap(dep => dep.children).map((team: Team) => (
                  <option key={team.id} value={team.id}>{team.name}</option>
                ))}
              </select>
            </div>
          )}
  
          {/* Для роли Manager: отображаем название отдела без возможности выбора */}
          {isNew && goalLevel === 'team' && userRole === 'Manager' && selectedTeam && (
            <div>
              <label>Отдел</label>
              <input
                type="text"
                value={selectedTeam.name}
                className="edit-goal-popup-input"
                disabled
              />
            </div>
          )}
          {isNew && goalLevel === 'team' && (
            <div>
              <label>Привязать к</label>
              <select
                name="parent"
                value={selectedParentGoal || ''}
                onChange={(e) => setSelectedParentGoal(Number(e.target.value))}
                className="edit-goal-popup-select"
                required
              >
                <option value="">Выберите цель уровня департамента</option>
                {getDepartmentGoals(goals, userCompany).map((goal) => (
                  <option key={goal.id} value={goal.id}>{goal.name}</option>
                ))}
              </select>
            </div>
          )}
  
          {/* Изменения для роли Manager: убрано поле выбора отдела для уровня "сотрудник" */}
          {isNew && goalLevel === 'employee' && userRole !== 'Manager' && userRole !== 'User' && (
            <>
              <div>
                <label>Отдел</label>
                <select
                  name="team"
                  className="edit-goal-popup-select"
                  onChange={(e) => {
                    const selected = userCompany
                      .flatMap(dep => dep.children)
                      .find(team => team.id === Number(e.target.value));
                    setSelectedTeam(selected || null);
                    setSelectedEmployee(null); // Сброс выбранного сотрудника при смене отдела
                  }}
                >
                  <option value="">Выберите отдел</option>
                  {userCompany.flatMap(dep => dep.children).map((team: Team) => (
                    <option key={team.id} value={team.id}>{team.name}</option>
                  ))}
                </select>
              </div>
  
              <div>
                <label>Сотрудник</label>
                <select
                  name="employee"
                  className="edit-goal-popup-select"
                  onChange={(e) => {
                    const selected = selectedTeam?.users.find(emp => emp.id === Number(e.target.value));
                    setSelectedEmployee(selected || null);
                  }}
                  disabled={!selectedTeam || assignToSelf}
                >
                  <option value="">Выберите сотрудника</option>
                  {selectedTeam?.users.map((employee: Employee) => (
                    <option key={employee.id} value={employee.id}>
                      {getEmployeeInitials(employee)}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
{isNew && goalLevel === 'employee' && (
  <div>
    <label>Привязать к</label>
    <select
      name="parent"
      value={selectedParentGoal || ''}
      onChange={(e) => setSelectedParentGoal(Number(e.target.value))}
      className="edit-goal-popup-select"
      required
    >
      <option value="">Выберите цель уровня отдела</option>
      {getAvailableParentGoals(goals, userCompany, selectedTeam?.id || null)
        .map((goal) => (
          <option key={goal.id} value={goal.id}>{goal.name}</option>
        ))
      }
    </select>
  </div>
)}
          {/* Для роли Manager: отображаем только поле выбора сотрудника из текущего отдела  Департамент*/}
          {isNew && goalLevel === 'employee' && userRole === 'Manager' && selectedTeam && (
  <>
    <div>
      <label>Сотрудник</label>
      <select
        name="employee"
        className="edit-goal-popup-select"
        onChange={(e) => {
          const selected = selectedTeam.users.find(emp => emp.id === Number(e.target.value));
          setSelectedEmployee(selected || null);
        }}
        disabled={assignToSelf}
      >
        <option value="">Выберите сотрудника</option>
        {selectedTeam.users.map((employee: Employee) => (
          <option key={employee.id} value={employee.id}>
            {getEmployeeInitials(employee)}
          </option>
        ))}
      </select>
    </div>

    <div>
  <label>
    <input
      type="checkbox"
      checked={assignToSelf}
      onChange={(e) => {
        const isChecked = e.target.checked;
        setAssignToSelf(isChecked);

        // Если чекбокс выбран, устанавливаем выбранного сотрудника как текущего пользователя
        if (isChecked) {
          const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
          setSelectedEmployee({
            id: currentUser.id,
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
            patronymic: currentUser.patronymic,
          });
        } else {
          // Если чекбокс снят, сбрасываем выбранного сотрудника
          setSelectedEmployee(null);
        }
      }}
    />
    Назначить цель на себя
  </label>
</div>
  </>
)}
  
          <div>
            <label>Название цели</label>
            <input
              type="text"
              name="title"
              value={editedGoal.title}
              onChange={handleChange}
              className="edit-goal-popup-input"
            />
          </div>
  
          <div>
            <label>Описание (до 250 символов)</label>
            <textarea
              name="description"
              value={editedGoal.description}
              onChange={handleChange}
              maxLength={250}
              className="edit-goal-popup-textarea"
            />
          </div>
  
          <div>
            <label>Тип цели</label>
            <select
              name="goalType"
              value={editedGoal.goalType}
              onChange={handleChange}
              className="edit-goal-popup-select"
            >
              <option value="non-binary">Не бинарная</option>
              <option value="binary">Бинарная</option>
            </select>
          </div>
  
          {editedGoal.goalType === 'binary' && (
            <div>
              <label>Статус</label>
              <select
                name="progress"
                value={editedGoal.progress === 100 ? "Выполнено" : "Не выполнено"}
                onChange={handleChange}
                className="edit-goal-popup-select"
              >
                <option value="Не выполнено">Не выполнено</option>
                <option value="Выполнено">Выполнено</option>
              </select>
            </div>
          )}
  
          {editedGoal.goalType === 'non-binary' && (
            <>
              <div>
                <label>Тип прогресса</label>
                <select
                  name="progressType"
                  value={editedGoal.progressType}
                  onChange={handleChange}
                  className="edit-goal-popup-select"
                >
                  <option value="numeric">Числовая цель</option>
                  <option value="percentage">Цель в процентах</option>
                  <option value="monetary">Цель в денежном выражении</option>
                </select>
              </div>
  
            <div>
              <label>
                {editedGoal.progressType === 'percentage'
                  ? 'Выполнено сейчас:'
                  : editedGoal.progressType === 'monetary'
                  ? 'Выполнено на:'
                  : 'Текущее значение:'}
              </label>
              <input
                type="number" // Ограничиваем ввод числовыми значениями
                name="currentValue"
                value={editedGoal.currentValue}
                onChange={handleChange}
                className="edit-goal-popup-input"
              />
            </div>

            <div>
              <label>Цель</label>
              <input
                type="number" // Ограничиваем ввод числовыми значениями
                name="target"
                value={editedGoal.target || ''}
                onChange={handleChange}
                className="edit-goal-popup-input"
                required
              />
            </div>
          </>
          )}
        </div>
  
        <div className="edit-goal-popup-buttons">
          <button onClick={onClose} className="edit-goal-popup-button edit-goal-popup-button-cancel">
            Отмена
          </button>
          {!isNew && (
            <button onClick={handleDelete} className="edit-goal-popup-button edit-goal-popup-button-delete">
              Удалить
            </button>
          )}
          <button onClick={handleSave} className="edit-goal-popup-button edit-goal-popup-button-save">
            Сохранить изменения
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default EditGoalPopup;