import './avatar.css';
import React from 'react';

// Типы для данных
type User = {
  id: number;
  name: string;
  position: string;
};

type EmployeesAvatarsProps = {
  employees: User[];
};

// Функция для генерации цвета на основе строки
const stringToColor = (string: string): string => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, 70%, 40%)`;
};

// Функция перевода должностей
const translateRole = (role: string): string => {
  const lowerRole = role.toLowerCase();
  
  if (lowerRole.includes('hr')) {
    return 'HR';
  }
  
  switch(lowerRole) {
    case 'manager': return 'Руководитель';
    case 'user': return 'Сотрудник';
    default: return role;
  }
};

// Компонент аватара пользователя
const UserAvatar: React.FC<{ user?: User }> = ({ user }) => {
  const initials = user?.name.split(' ').map(n => n[0]).join('').toUpperCase();
  return (
    <div className="user-avatar" style={{ backgroundColor: stringToColor(user?.name || '') }}>
      {initials}
    </div>
  );
};

// Основной компонент EmployeesAvatars
const EmployeesAvatars: React.FC<EmployeesAvatarsProps> = ({ employees }) => {
  return (
    <div className="employees-avatars">
      {employees.map(employee => (
        <div key={employee.id} className="employee-avatar">
          <UserAvatar user={employee} />
          <div className="employee-info">
            <span className="employee-name">{employee.name}</span>
            <span className="employee-position">
              {translateRole(employee.position)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmployeesAvatars;