import React, { useRef, useEffect } from 'react';
import {RouterProvider} from "react-router-dom";
import { router } from './providers';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import './styles/index.scss';
const theme = createTheme();

export const App: React.FC = () => {

    return (
        <ThemeProvider theme={theme}>
          {/* <div id="bot"/>
          <script src="https://sf-cdn.coze.com/obj/unpkg-va/flow-platform/chat-app-sdk/1.0.0-beta.4/libs/oversea/index.js"></script>
          <script>
            {"const width = 800;const layout = 'pc';new CozeWebSDK.WebChatClient({config: {bot_id: '7434506741343060023',},componentProps: {title: 'Coze', icon: '', width: width, layout: layout,}, el: document.getElementById('bot')})"}
          </script> */}
          <ChakraProvider>
            <ConfigProvider locale={ruRU}>
               <RouterProvider router={router} />
                </ConfigProvider>
          </ChakraProvider>
        </ThemeProvider>
    );
};
