import React, { useState, useEffect } from 'react';
import { getGoals, createGoal, updateGoal } from '@/features/analytic';
import GoalHR from './GoalHR/GoalHR';
import GoalManager from './GoalManager/GoalManager';
import './GoalManager/GoalManager.css';
import EditGoalPopup from './GoalManager/popup';
import GoalUser from './GoalUser/GoalUser';
import { getCompany } from '@/entities/company/api';
import { toast } from 'react-toastify'; // Для уведомлений

// Типы для данных
type Employee = {
  name: string;
  position: string;
};

type Goal = {
  id: number;
  title: string;
  type: string;
  progress: number;
  linked: number[];
  children: Goal[];
  employee?: string;
  format?: string;
  goalType?: 'binary' | 'non-binary';
  company?: number;
  group?: number;
  parent?: number;
  description: any;
  archived?: boolean;
};

type Unit = {
  id: number;
  name: string;
  head: string;
  employees: Employee[];
  goals: Goal[];
};

type Department = {
  id: number;
  name: string;
  head: string;
  units: Unit[];
  goals: Goal[];
};

export const Goals: React.FC = () => {
  const storageUser = JSON.parse(localStorage.getItem('user') || '{}');
  const companyId = storageUser?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  const [user, setUser] = useState<string | null>(null);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [editingGoal, setEditingGoal] = useState<any>(null);
  const [isNewGoal, setIsNewGoal] = useState(false);
  const [departmentData, setDepartmentData] = useState<Department | null>(null);
  const [goalsList, setGoalsList] = useState<Goal[]>([]); // Список всех целей

  useEffect(() => {
    getCompany(companyId)
    .then((response) => {
      let groups = response.data.group;
    })
    .catch((error) => {
      console.log(error);
    })
  }, [])

  // Загрузка целей с сервера
  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const response = await getGoals();
        const goalsData = response.data;

        // Преобразуем данные с сервера в структуру, которую ожидает компонент
        const transformedGoals: Goal[] = goalsData.map((goal: any) => ({
          id: goal.id,
          title: goal.name,
          type: goal.goal_type_description,
          progress: goal.progress,
          linked: goal?.linked || null,
          children: goal?.children || null,
          format: goal.goal_type_description === 'Binary' ? 'binary' : 'percentage',
          goalType: goal.goal_type === 0 ? 'binary' : 'non-binary', // Преобразуем goal_type
          company: goal.company,
          group: goal.group,
          parent: goal.parent,
          description: goal.description,
        }));

        setGoalsList(transformedGoals);

        // Создаем моковые данные отдела с целями, полученными с сервера
        const mockDepartmentData: Department = {
          id: 1,
          name: 'Технологический отдел',
          head: 'Иван Петров',
          units: [
            {
              id: 1,
              name: 'Разработка',
              head: 'Сергей Иванов',
              employees: [
                { name: 'Анна Сидорова', position: 'Frontend разработчик' },
                { name: 'Михаил Козлов', position: 'Backend разработчик' },
              ],
              goals: transformedGoals.filter((goal: any) => goal.group === 1), // Фильтруем цели для группы 1
            },
            {
              id: 2,
              name: 'Тестирование',
              head: 'Ольга Смирнова',
              employees: [
                { name: 'Дмитрий Волков', position: 'QA инженер' },
                { name: 'Екатерина Новикова', position: 'QA автоматизатор' },
              ],
              goals: transformedGoals.filter((goal: any) => goal.group === 2), // Фильтруем цели для группы 2
            },
          ],
          goals: transformedGoals.filter((goal: any) => !goal.group), // Цели без группы (департаментные)
        };

        setDepartmentData(mockDepartmentData);
      } catch (error) {
        console.error('Ошибка при загрузке целей:', error);
      }
    };

    fetchGoals();

    // Определяем роль пользователя
    if (storageUser?.role === 'HR manager') {
      setUser('HR manager');
    } else if (storageUser?.role === 'Super HR manager') {
      setUser('Super HR manager');
    } else if (storageUser?.role === 'User') {
      setUser('User');
    } else if (storageUser?.role === 'Manager') {
      setUser('Manager');
    }
  }, [storageUser?.role]);

  const handleSaveGoal = async (updatedGoal: any) => {
    try {
      if (isNewGoal) {
        console.log({
          name: updatedGoal.title,
          description: updatedGoal.description,
          goal_type: updatedGoal.goal_type, // Используем числовое значение для goal_type
          company: updatedGoal.company,
          group: updatedGoal.group,
          progress: updatedGoal.progress,
          parent: updatedGoal.parent || null, // Добавляем родительскую цель, если выбрана
          responsible: updatedGoal.responsible || null,
          target: updatedGoal.target || null
        })
        // Создание новой цели
        const response = await createGoal({
          name: updatedGoal.title,
          description: updatedGoal.description,
          goal_type: updatedGoal.goal_type, // Используем числовое значение для goal_type
          company: updatedGoal.company,
          group: updatedGoal.group,
          progress: updatedGoal.progress,
          parent: updatedGoal.parent || null, // Добавляем родительскую цель, если выбрана
          responsible: updatedGoal.responsible || null,
          target: updatedGoal.target || null
        });
        console.log('Новая цель создана:', updatedGoal);
        toast.success('Новая цель успешно создана!');
      } else {
        // Обновление существующей цели
        const originalGoal = goalsList.find((goal) => goal.id === updatedGoal.id);
  
        if (!originalGoal) {
          throw new Error('Цель не найдена');
        }
  
        const updatedFields: any = {};
  
        // Добавляем только измененные поля
        if (updatedGoal.title !== originalGoal.title) updatedFields.name = updatedGoal.title;
        if (updatedGoal.description !== originalGoal.description) updatedFields.description = updatedGoal.description;
        if (updatedGoal.progress !== originalGoal.progress) updatedFields.progress = updatedGoal.progress;
  
        // Преобразуем goalType в число
        updatedFields.goal_type = updatedGoal.goal_type; // Используем числовое значение для goal_type
  
        // Поля company, group, parent не изменяются, если их не трогали
        updatedFields.company = originalGoal.company;
        updatedFields.group = originalGoal.group;
        updatedFields.parent = originalGoal.parent;
  
        // Отправляем запрос на обновление
        const response = await updateGoal(updatedGoal.id, updatedFields);
        console.log('Цель успешно обновлена:', updatedFields);
        toast.success('Цель успешно обновлена!');
      }
    } catch (error) {
      console.error('Ошибка при сохранении цели:', error);
      toast.error('Ошибка при сохранении цели');
    } finally {
      setEditingGoal(null);
      setIsNewGoal(false);
      setTimeout(() => {
        window.location.reload();// Перезагружаем страницу
      }, 500);
    }
  };

  const handleAddGoal = (newGoal: Goal) => {
    console.log('Новая цель добавлена:', newGoal);
  };

  const handleLinkGoal = (goal: Goal) => {
    console.log('Цель привязана:', goal);
  };

  if (isAwaiting) {
    return <>Загрузка...</>;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Цели</h1>
        <button
          className="add-goal-btn"
          onClick={() => {
            setEditingGoal({
              title: '',
              description: '',
              format: 'percentage',
              progress: 0, // Добавлено для совместимости с типом Goal
              children: [], // Добавлено для совместимости с типом Goal
            });
            setIsNewGoal(true);
          }}
        >
          + Новая цель
        </button>
      </div>
      {user === 'HR manager' && <GoalHR />}
      {user === 'Super HR manager' && <GoalHR />}
      {user === 'User' && departmentData && (
        <GoalUser
          department={departmentData}
          isDepartmentHead={true}
          onAddGoal={handleAddGoal}
          onLinkGoal={handleLinkGoal}
        />
      )}
      {user === 'Manager' && departmentData && (
        <GoalManager
          department={departmentData}
          isDepartmentHead={true}
          onAddGoal={handleAddGoal}
          onLinkGoal={handleLinkGoal}
        />
      )}

      {editingGoal && (
        <EditGoalPopup
          goal={editingGoal}
          onSave={handleSaveGoal}
          onClose={() => {
            setEditingGoal(null);
            setIsNewGoal(false);
          }}
          isNew={isNewGoal}
        />
      )}
    </>
  );
};