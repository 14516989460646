import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '@/widgets';
import { Outlet } from 'react-router-dom';
import { Page, Board } from './styles';

const skillSystemCategories = [
  {
    id: 1,
    title: 'Личное',
    links: [
      {
        id: 1,
        title: 'Опыт и навыки',
        to: '/dashboard-employee-profile',
      },
      {
        id: 2,
        title: 'Проекты и задачи',
        to: '/dashboard-employee-tasks',
      },
      {
        id: 3,
        title: 'Моя карьера',
        to: '/dashboard-employee-career',
      },
      {
        id: 4,
        title: 'Курсы',
        to: '/dashboard-employee-courses',
      },
      {
        id: 5,
        title: 'Тесты',
        to: '/dashboard-employee-tests',
      },
          {
            id: 6,
            title: 'Цели и задачи',
            to: '/dashboard-goals',
        },
    ],
  },
  {
    id: 2,
    title: 'Другое',
    links: [
      {
        id: 6,
        title: 'Ярмарка проектов',
        to: '/dashboard-employee-projects',
      },
    ],
  },
];

const competenceSystemCategories = [
  {
    id: 1,
    title: 'Профиль развития',
    links: [
      {
        id: 1,
        title: 'Мой профиль',
        to: '/dashboard-employee-profile',
      },
      {
        id: 2,
        title: 'Карьерный трек',
        to: '/dashboard-employee-career',
      },
      {
        id: 3,
        title: 'Обучение',
        to: '/dashboard-employee-courses',
      },
      {
        id: 4,
        title: 'Тесты и опросы',
        to: '/dashboard-employee-tests',
      },
    ],
  },
  {
    id: 2,
    title: 'Открытые возможности',
    links: [
      {
        id: 5,
        title: 'Поиск проектов и задач',
        to: '/dashboard-employee-projects',
      },
      {
        id: 6,
        title: 'Мои проекты и задачи',
        to: '/dashboard-employee-tasks',
      },
    ],
  }
]

export const EmployeeLayout: React.FC = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));

  useEffect(() => {
      if (!user) {
        navigate('/');
      }
  
      if (user?.role !== 'User') {
        navigate('/');
      }
  }, [])

  return (
    <Page>
      <Navbar categories={company.competence_system ? competenceSystemCategories : skillSystemCategories}/>
      <Board>
          <Outlet />
      </Board>
    </Page>
  )
}
