import './Goal.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import EditGoalPopup from './EditGoalPopup';
import { getGoals, createGoal, updateGoal, deleteGoal } from '@/features/analytic'; // Импорт функции для удаления целей
import { getCompany } from '@/entities/company/api';
import { toast } from 'react-toastify'; // Для уведомлений
import { getEmployees } from '@/features/employee';
import { Card, CardContent, Typography, Avatar } from '@mui/material';
// Типы для данных
type User = {
  name: string;
};

type Goal = {
  target?:any;
  responsible?: any;
  archived: any;
  id: number; // Убедитесь, что тип id - number
  title: string;
  progress: number | string; // Прогресс может быть числом или строкой ("Да"/"Нет")
  description?: string;
  format: "percentage" | "monetary" | "numeric" | "binary";
  createdAt: string;
  updatedAt: string;
  subGoals: Goal[];
  department?: string;
  assignee?: User;
  company: number;
  goalType: number; // Добавлено поле goalType
  parent?: number | null; // Добавлено поле parent
  group?: number; // Добавлено поле group
};

type CompanyGroup = {
  company: number;
  goals: Goal[];
};

type ExpandedGoals = {
  [key: string]: boolean;
};
type SelectedGoalInfo = Goal & { level: number };

// Функция для генерации цвета на основе строки
const stringToColor = (string: string): string => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, 70%, 40%)`;
};
const UserAvatar: React.FC<{ user: any }> = ({ user }) => {
  // Формируем полное имя из first_name, last_name и patronymic
  const fullName = `${user?.last_name || ''} ${user?.first_name || ''}`.trim();

  // Если имя пустое, используем fallback
  const name = fullName || "Unknown User";

  // Формируем инициалы
  const initials = name
    .split(' ') // Разделяем имя по пробелам
    .map((n) => n[0]) // Берем первую букву каждого слова
    .join('') // Объединяем буквы
    .toUpperCase(); // Переводим в верхний регистр

  // URL для ссылки (можно заменить на нужный)
  const userProfileUrl = `/dashboard-hr-profile?search=${user?.id}`;

  return (
    <a
      href={userProfileUrl} // Указываем URL для перехода
      className="user-avatar-link" // Добавляем класс для стилизации
      style={{ textDecoration: 'none', color: 'inherit' }} // Убираем подчеркивание и наследуем цвет
    >
      <div className="user-avatar" style={{ backgroundColor: stringToColor(name) }}>
        {initials}
      </div>
    </a>
  );
};

// Функция для получения названия уровня
const getLevelName = (level: number): string => {
  switch (level) {
    case 0: return 'Департамент';
    case 1: return 'Команда';
    case 2: return 'Индивидуальный';
    default: return 'Задача';
  }
};

const GoalHR: React.FC = () => {
  const navigate = useNavigate();

  const [editingGoal, setEditingGoal] = useState<Goal | null>(null);
  const [expandedGoals, setExpandedGoals] = useState<ExpandedGoals>({});
  const [selectedGoalInfo, setSelectedGoalInfo] = useState<SelectedGoalInfo | null>(null);
  const [companyGroups, setCompanyGroups] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Состояние для индикатора загрузки
  const [userCompany, setUserCompany] = useState<any>(null);
  const [unit, setUnit] = useState<any[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [isDepartmentPopupVisible, setIsDepartmentPopupVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [groupedGoals, setGroupedGoals] = useState<Record<string, Goal[]>>({});

// Маппинг для goalType
const goalTypeMapping: Record<number, string> = {
  0: 'Бинарная',
  1: 'Числовая',
  2: 'Процентная',
  3: 'Денежная',
};
const groupGoalsByDepartment = (goals: Goal[], departments: any[]): Record<string, Goal[]> => {
  const grouped: Record<string, Goal[]> = {};

  // Инициализируем все департаменты
  departments.forEach((dep) => {
    grouped[dep.name] = [];
  });

  console.log(grouped);

  // Добавляем цели в соответствующие департаменты
  goals.forEach((goal) => {
    if (!goal.archived) {
      // Проверяем, относится ли цель к департаменту
      const department = departments.find((dep) => dep.id === goal.group);

      if (department) {
        // Если цель относится к департаменту, добавляем ее в соответствующий департамент
        grouped[department.name].push(goal);
      } else {
        // Если цель не относится к департаменту, проверяем, относится ли она к отделу
        const teamDepartment = departments.find((dep) =>
          dep.children?.some((child: any) => child.id === goal.group)
        );

        if (teamDepartment) {
          // Если цель относится к отделу, добавляем ее в соответствующий департамент
          grouped[teamDepartment.name].push(goal);
        }
        // Если цель не относится ни к департаменту, ни к отделу, игнорируем ее
      }
    }
  });

  console.log(grouped);

  return grouped;
};
  const companyId = JSON.parse(
    localStorage.getItem('user')
  )?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    getCompany(companyId)
      .then((res: any) => {
        setUserCompany(res.data);
        setDepartments(res.data.group);
        console.log(res.data.group);
        // Получаем все департаменты из всех групп компаний
        const allDepartments = res.data.group.flatMap((group: any) => group.children || []);
        setUnit(allDepartments);

        getGoals()
          .then((res: any) => {
            const transformedGoals: Goal[] = res.data.map((goal: any) => ({
              id: goal.id,
              title: goal.name,
              progress: goal.progress || 0,
              description: goal.description,
              format: ['binary', 'percentage', 'monetary', 'numeric'][goal.goal_type] as Goal['format'],
              createdAt: goal.created_on,
              updatedAt: goal.modified,
              parent: goal.parent?.id || null,
              department: goal.group,
              company: goal.company,
              goalType: goal.goal_type,
              group: goal.group,
              archived: goal.archived || false,
              responsible: goal.responsible,
              target: goal.target
            }));
            transformedGoals.filter((elem)=>elem.archived)
            const hierarchicalGoals = buildHierarchy(transformedGoals, departments);
            console.log(transformedGoals)
            const groupedByCompany: Record<number, Goal[]> = {};

            groupedByCompany[companyId] = []

            hierarchicalGoals.forEach((goal) => {
              console.log('goal.company:', goal.company);
              console.log('groupedByCompany[goal.company]:', groupedByCompany[goal.company]);
              groupedByCompany[goal.company] = groupedByCompany[goal.company] || [];
              groupedByCompany[goal.company].push(goal);
            });

            setCompanyGroups(Object.entries(groupedByCompany).map(([companyId, goals]) => ({
              company: parseInt(companyId, 10),
              goals,
            })));
          })
          .catch(console.error)
          .finally(() => setIsLoading(false));
        })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const buildHierarchy = (goals: Goal[], departments: any[]): Goal[] => {
    const goalMap: Record<number, Goal> = {};
    const rootGoals: Goal[] = [];
  
    // Создаем карту целей
    goals.forEach(goal => {
      if (!goal.archived) {
        goalMap[goal.id] = { ...goal, subGoals: [] };
      }
    });
  
    // Строим иерархию на основе parent и group
    goals.forEach(goal => {
      if (!goal.archived) {
        if (goal.parent) {
          // Если цель имеет parent, добавляем ее как подцель
          const parent = goalMap[goal.parent];
          if (parent) {
            parent.subGoals.push(goalMap[goal.id]);
          }
        } else {
          // Если цель не имеет parent, проверяем, относится ли она к отделу
          const department = departments.find(dep => 
            dep.children?.some((child: any) => child.id === goal.group)
          );
          if (department) {
            // Если цель относится к отделу, добавляем ее как подцель департамента
            const departmentGoal = goalMap[department.id];
            if (departmentGoal) {
              departmentGoal.subGoals.push(goalMap[goal.id]);
            } else {
              console.log('департамент', department.name)
              // Если департамент еще не добавлен, создаем его
              goalMap[department.id] = {
                id: department.id,
                title: department.name,
                progress: 0,
                format: "percentage",
                createdAt: "",
                updatedAt: "",
                subGoals: [goalMap[goal.id]],
                company: goal.company,
                goalType: 0,
                archived: false,
              };
              rootGoals.push(goalMap[department.id]);
            }
          } else {
            // Если цель не относится к отделу, добавляем ее как корневую
            rootGoals.push(goalMap[goal.id]);
          }
        }
      }
    });
  
    return rootGoals;
  };

  const startEditingGoal = (goal: Goal, e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingGoal(goal);
  };

  const toggleGoal = (path: string) => {
    setExpandedGoals(prev => ({ ...prev, [path]: !prev[path] }));
  };
  useEffect(()=>{console.log(companyGroups)},[companyGroups])
  const saveEditedGoal = async (updatedGoal: Goal) => {
    try {
      if (!editingGoal) {
        throw new Error('Цель для обновления не найдена');
      }
  
      const updatedFields: any = {
        name: updatedGoal.title,
        description: updatedGoal.description,
        progress: updatedGoal.progress,
        group: editingGoal.group,
        goal_type: editingGoal.goalType,
        company: editingGoal.company,
        parent: editingGoal.parent,
        archived: updatedGoal.archived, // Добавляем archived
      };
  
      const response = await updateGoal(editingGoal.id, updatedFields);
      console.log('Цель успешно обновлена:', response.data);
      toast.success('Цель успешно обновлена!');
  
      setEditingGoal(null);
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при обновлении цели:', error);
      toast.error('Ошибка при обновлении цели');
    }
  };

  // Функция для удаления цели
  const handleDeleteGoal = async (goalId: number) => {
    try {
      // Отправляем запрос на удаление цели
      await deleteGoal(goalId);
      console.log('Цель успешно удалена:', goalId);
      toast.success('Цель успешно удалена!');

      // Закрываем попап
      setEditingGoal(null);

      // Перезагружаем страницу для обновления данных
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при удалении цели:',goalId, error);
      toast.error('Ошибка при удалении цели');
    }
  };

  const closeEditPopup = () => setEditingGoal(null);

  const showGoalInfo = (goal: Goal, level: number, e: React.MouseEvent) => {
    e.stopPropagation();
    console.log(goal, level)
    setSelectedGoalInfo({ ...goal, level });
  };
///responsible 
  const closeGoalInfo = () => setSelectedGoalInfo(null);
  const renderProgress = (goal: Goal) => {
    switch (goal.goalType) {
      case 0: // BINARY
        return (
          <span className="goal-progress-text">
            {goal.progress === 100 ? 'Выполнено' : 'Не выполнено'}
          </span>
        );
      case 1: // PROGRESS_INTEGER (numeric)
        return (
          <span className="goal-progress-text">
            Выполнено на: <strong>{goal.progress}</strong> из <strong>{goal.target}</strong>
          </span>
        );
      case 2: // PROGRESS_PERCENT
        return (
          <>
            <div className="goal-progress-bar">
              <div className="goal-progress-fill" style={{ width: `${Number(goal.progress) / goal.target * 100}%` }}/>
            </div>
            <span className="goal-progress-text">{goal.progress}%</span>
          </>
        );
      case 3: // PROGRESS_MONEY (monetary)
        return (
          <span className="goal-progress-text">
            Выполнено на: <strong>{goal.progress.toLocaleString()} ₽</strong> из <strong>{goal.target?.toLocaleString()} ₽</strong>
          </span>
        );
      default:
        return null;
    }
  };
  const findUserById = (userId: number, departments: any[]): User | null => {
    for (const department of departments) {
      // Проверяем, есть ли в департаменте отделы (children)
      if (department.children) {
        for (const child of department.children) {
          // Проверяем, есть ли в отделе пользователи (users)
          if (child.users) {
            const user = child.users.find((u: any) => u.id === userId);
            if (user) {
              return user;
            }
          }
        }
      }
    }
    return null; // Если пользователь не найден
  };
  //groupGoalsByDepartment 
  const formatDate = (dateString: string): string => {
    if (!dateString) return 'Не указана';
  
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const renderGoals = (goals: Goal[] | undefined, companyId: number) => {
//     if (!goals?.length) {
//       return <div className="no-goals">Нет целей для отображения</div>;
//     }

    console.log(companyGroups);

    // Группируем цели по департаментам
    const groupedGoals = groupGoalsByDepartment(goals, departments);
    return (
      <>
        {Object.entries(groupedGoals).map(([departmentName, goals], idx) => {
          const currentPath = `${idx}`;
          const isExpanded = expandedGoals[currentPath];

          return (
            <div key={departmentName}>
              {/* Карточка департамента */}
              <motion.div
                className="department-card"
                onClick={() => toggleGoal(currentPath)}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="department-header">
                  <p
                    className="department-title-button"
                  >
                    <strong>Департамент:</strong> {departmentName}
                  </p>
                  <motion.div
                    className="department-expand-icon"
                    animate={{ rotateX: isExpanded ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
                    </svg>
                  </motion.div>
                </div>
              </motion.div>

              {/* Цели департамента */}
              <AnimatePresence>
                {isExpanded && (
                  <motion.div
                    className="department-goals-container"
                    initial={{ opacity: 0, maxHeight: 0 }}
                    animate={{ opacity: 1, maxHeight: 1000 }}
                    exit={{ opacity: 0, maxHeight: 0 }}
                  >
                    {goals.length > 0 ? (
                      goals.map((goal, goalIdx) => (
                        <div key={`${currentPath}-${goalIdx}`}>
                          {/* Карточка цели */}
                          <motion.div
                            className="goal-container"
                            style={{ backgroundColor: `rgba(240, 240, 240, 0.8)` }}
                            onClick={() => toggleGoal(`${currentPath}-${goalIdx}`)}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            
                            <div className="goal-header">
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "5px" }}>
                                <span className="goal-title">{goal.title}</span>
                                {renderProgress(goal)}
                                {!goal.subGoals.length && goal.assignee && <UserAvatar user={goal.assignee} />}
                                <span
                                  className={departments.find(department => department.id === goal.group) ? '' : 'sub-goal-button'}
                                  style={{ color: "#757575" }}
                                  onClick={() => {
                                    const department = unit.find(d => d.id === goal.group);
                                    if (department && !goal.responsible) {
                                      setSelectedDepartment(department);
                                      setIsDepartmentPopupVisible(true);
                                    }
                                    else if (goal.responsible) {
                                      navigate(`/dashboard-hr-profile?search=${goal.responsible}`)
                                    }
                                  }}
                                >
                                  {goal.responsible ?
                                    'Личная'
                                  :
                                    departments.find(department => department.id === goal.group) ?
                                      'Департамент'
                                    :
                                      'Отдел ' + unit.find(d => d.id === goal.group)?.name
                                  }
                                </span>
                              </div>
                              {!goal.parent && 
                              unit.some(d => d.id === goal.group) && 
                              goal.goalType !== 0 && ( // Пример дополнительного условия, если нужно
                                <div className="unlinked-warning">
                                  Цель не привязана к другим целям
                                </div>
                              )}
                              <div className="goal-actions">
                                {goal.responsible && (
                                  <div className="responsible-avatar">
                                    <UserAvatar user={findUserById(goal.responsible, departments)} />
                                  </div>
                                )}

                                <button className="info-button" onClick={(e) => showGoalInfo(goal, 1, e)}>
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
                                  </svg>
                                </button>

                                <button className="goal-edit-button" onClick={(e) => startEditingGoal(goal, e)}>
                                  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                                  </svg>
                                </button>
                                
                                {goal.subGoals.length > 0 && (
                                  <motion.div
                                    className="goal-expand-icon"
                                    animate={{ rotate: expandedGoals[`${currentPath}-${goalIdx}`] ? 180 : 0 }}
                                    transition={{ duration: 0.3 }}
                                  >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
                                    </svg>
                                  </motion.div>
                                )}
                              </div>
                            </div>
                          </motion.div>

                          {/* Подцели */}
                          <AnimatePresence>
                            {expandedGoals[`${currentPath}-${goalIdx}`] && goal.subGoals.length > 0 && (
                              <motion.div
                                className="sub-goal-container"
                                initial={{ opacity: 0, maxHeight: 0 }}
                                animate={{ opacity: 1, maxHeight: 1000 }}
                                exit={{ opacity: 0, maxHeight: 0 }}
                              >
                                {goal.subGoals.map((subGoal, subGoalIdx) => (
                                  <div key={`${currentPath}-${goalIdx}-${subGoalIdx}`}>
                                    {/* Карточка подцели */}
                                    <motion.div
                                      className="goal-container"
                                      style={{ backgroundColor: `rgba(240, 240, 240, 0.6)` }}
                                      initial={{ opacity: 0, y: -10 }}
                                      animate={{ opacity: 1, y: 0 }}
                                      transition={{ duration: 0.2 }}
                                    >
                                      <div className="goal-header">
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "5px" }}>
                                          <span className="goal-title">{subGoal.title}</span>
                                          {renderProgress(subGoal)}
                                          <span
                                            className="sub-goal-button"
                                            style={{ color: "#757575" }}
                                            onClick={() => {
                                              const department = unit.find(d => d.id === subGoal.group);
                                              if (department && !subGoal.responsible) {
                                                setSelectedDepartment(department);
                                                setIsDepartmentPopupVisible(true);
                                              }
                                              else if (subGoal.responsible) {
                                                const navigate = useNavigate();
                                                navigate(`/dashboard-hr-profile?search=${subGoal.responsible}`)
                                              }
                                            }}
                                          >
                                            {subGoal.responsible ?
                                              'Личная'
                                            :
                                              'Отдел ' + unit.find(d => d.id === subGoal.group)?.name
                                            }
                                          </span>
                                          {subGoal.assignee && <UserAvatar user={subGoal.assignee} />}
                                        </div>

                                        <div className="goal-actions">
                                          {subGoal.responsible && (
                                            <div className="responsible-avatar">
                                              <UserAvatar user={findUserById(subGoal.responsible, departments)} />
                                            </div>
                                          )}

                                          <button className="info-button" onClick={(e) => showGoalInfo(subGoal, 2, e)}>
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
                                            </svg>
                                          </button>

                                          <button className="goal-edit-button" onClick={(e) => startEditingGoal(subGoal, e)}>
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                                              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </motion.div>
                                  </div>
                                ))}
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      ))
                    ) : (
                      <div className="no-goals">Цели еще не назначены</div>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          );
        })}
      </>
    );
  };
  // Индикатор загрузки
  if (isLoading) {
    return <div className="loading">Загрузка целей...</div>;
  }
  // Функция для преобразования иерархических целей в плоский список
const flattenGoals = (goals: Goal[]): Goal[] => {
  return goals.reduce<Goal[]>((acc, goal) => {
    // Добавляем текущую цель
    acc.push(goal);
    // Рекурсивно добавляем подцели
    if (goal.subGoals && goal.subGoals.length > 0) {
      acc.push(...flattenGoals(goal.subGoals));
    }
    return acc;
  }, []);
};
  return (
    <div>
     <div>
     <AnimatePresence>
     <AnimatePresence>
  {isDepartmentPopupVisible && selectedDepartment && (
    <motion.div
      className="popup-overlay"
      onClick={() => setIsDepartmentPopupVisible(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content"
        onClick={(e) => e.stopPropagation()}
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
      >
        <button className="popup-close-button" onClick={() => setIsDepartmentPopupVisible(false)}>
          ×
        </button>
        <h3 style={{padding:'15px 0'}}>Информация об отделе</h3>

        {/* Название отдела */}
        <div className="department-info">
          <strong>Отдел:</strong> {selectedDepartment.name}
        </div>
        <div className="department-employees">
  <strong>Сотрудники отдела:</strong>
  <div 
    style={{
      display: 'flex',
      flexWrap: 'wrap', // Перенос на новую строку, если не хватает места
      gap: '12px', // Отступ между карточками
      marginTop: '12px',
    }}
  >
    {selectedDepartment.users?.map((user: any) => (
      <motion.div
        key={user.id}
        className="employee-card"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          padding: '12px',
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Тень
          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          cursor: 'pointer',
        }}
        whileHover={{ 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Увеличенная тень при наведении
          transform: 'translateY(-2px)', // Легкий подъем при наведении
        }}
      >
        {/* Аватар сотрудника */}
        <div 
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#466DF2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ffffff',
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          {`${user.first_name[0]}${user.last_name[0]}`.toUpperCase()}
        </div>

        {/* Имя и фамилия сотрудника */}
        <div>
          <div 
            style={{
              fontSize: '16px',
              fontWeight: '500',
            }}
          >
            {user.first_name} {user.last_name}
          </div>
          <div 
            style={{
              fontSize: '14px',
              color: '#666666',
            }}
          >
            {user.position || 'Должность не указана'}
          </div>
        </div>
      </motion.div>
    ))}
  </div>
</div>
        {/* Список сотрудников */}
  

        {/* Список целей */}
        <div className="department-goals">
  <h4>Цели отдела:</h4>
  {flattenGoals(companyGroups.flatMap(group => group.goals))
    .filter(goal => 
      goal.group === selectedDepartment.id && 
      !goal.archived
    )
    .map((goal) => (
      <div 
        key={goal.id} 
        className="goal-card"
        style={{
          display: 'flex',
          justifyContent: 'space-between', // Разделяем левую и правую части
          alignItems: 'center', // Выравниваем по вертикали
          padding: '12px',
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          marginBottom: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        {/* Левая часть: Название и прогресс */}
        <div 
          style={{
            flex: 1, // Занимает всё доступное пространство
            marginRight: '12px', // Отступ от аватара
            display: 'flex',
            alignItems: 'center', // Выравниваем элементы по вертикали
            gap: '12px', // Отступ между элементами
          }}
        >
          {/* Название цели */}
          <div 
            style={{
              fontSize: '16px',
              fontWeight: '500',
              minWidth: '150px', // Минимальная ширина для названия
            }}
          >
            {goal.title}
          </div>

          {/* Прогресс бар и проценты */}
          <div 
            style={{
              display: 'flex',
              alignItems: 'center', // Выравниваем прогресс бар и проценты
              gap: '8px', // Отступ между прогресс баром и процентами
            }}
          >
            {renderProgress(goal)}
          </div>
        </div>

        {/* Правая часть: Аватар */}
        {goal.responsible && (
          <div 
            style={{
              marginLeft: '12px', // Отступ от левой части
            }}
          >
            <UserAvatar user={findUserById(goal.responsible, departments)} />
          </div>
        )}
      </div>
    ))}
</div>
      </motion.div>
    </motion.div>
  )}
</AnimatePresence>
</AnimatePresence>
      {companyGroups.length > 0 ? (
        companyGroups
          .map((group, index) => (
            <div key={group?.company || group?.id} className="company-container">
              {renderGoals(group.goals, group.company)}
            </div>
          ))
      ) : (
        <div className="no-companies">Нет данных</div>
      )}
    </div>

      {editingGoal && (
        <EditGoalPopup
          goal={editingGoal}
          onSave={saveEditedGoal}
          onClose={closeEditPopup}
          onDelete={handleDeleteGoal} // Передаем функцию удаления
        />
      )}

      <AnimatePresence>
        {selectedGoalInfo && (
          <motion.div
            className="info-modal-overlay"
            onClick={closeGoalInfo}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="info-modal-content"
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
            >
              <button className="modal-close-button" onClick={closeGoalInfo}>×</button>
              <h3>{selectedGoalInfo.title}</h3>
              <div className="info-modal-body">
                <div className="info-row">
                  <span className="info-label">Уровень:</span>
                  <span className="info-value">
                    {getLevelName(selectedGoalInfo.level)}
                  </span>
                </div>
                
                <div className="info-row">
                  <span className="info-label">Формат:</span>
                  <span className="info-value">
                  {goalTypeMapping[selectedGoalInfo.goalType] || 'Не указан'}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Прогресс:</span>
                  <span className="info-value">
                  {selectedGoalInfo.goalType === 0 ? ( // Бинарная цель
                    selectedGoalInfo.progress === 100 ? 'Выполнено' : 'Не выполнено'
                  ) : selectedGoalInfo.goalType === 3 ? ( // Денежная цель
                   `${selectedGoalInfo.progress.toLocaleString()} / ${selectedGoalInfo.target}₽`
                  ) : selectedGoalInfo.goalType === 2 ? ( // Процентная цель
                   `${selectedGoalInfo.progress} / ${selectedGoalInfo.target}%`
                  ) : (
                  // Числовая цель (или другие типы, если они есть)
                  selectedGoalInfo.progress
                  )}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Дата создания:</span>
                  <span className="info-value">
                    {formatDate(selectedGoalInfo.createdAt) || 'Не указано'}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Дата изменения:</span>
                  <span className="info-value">
                    {formatDate(selectedGoalInfo.updatedAt) || 'Не указано'}
                  </span>
                </div>

                {selectedGoalInfo.description && (
                  <div className="info-description">
                    <div className="description-label">Описание:</div>
                    <p className="description-text">
                      {selectedGoalInfo.description}
                    </p>
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GoalHR;