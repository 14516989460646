import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getAnalyticForCompany = async (type: string, companyId: number, startDate?: string, endDate?: string) => {
  const apiType = type === 'inProgress' ? 'in-progress' : 'current';

  const res = await axios<any>({
    url: `${API}/analytics/${apiType}/?for=company&company_id=${companyId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 

export const getAnalyticForGroup = async (type: string, groupId: number, startDate?: string, endDate?: string) => {
  const apiType = type === 'inProgress' ? 'in-progress' : 'current';

  const res = await axios<any>({
    url: `${API}/analytics/${apiType}/?for=group&group_id=${groupId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 

export const getAnalyticForEmployee = async (type: string, employeeId: number, startDate?: string, endDate?: string) => {
  const apiType = type === 'inProgress' ? 'in-progress' : 'current';

  const res = await axios<any>({
    url: `${API}/analytics/${apiType}/?for=user&user_id=${employeeId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 


export const getGoals = async (params?: { group?: number; company?: number, responsible?:any }) => {
  const queryParams = new URLSearchParams();

  // Добавляем параметры, если они переданы
  if (params?.group !== undefined) {
    queryParams.append('group', params.group.toString());
  }
  if (params?.company !== undefined) {
    queryParams.append('company', params.company.toString());
  }
  if (params?.responsible !== undefined) {
    queryParams.append('responsible', params.responsible.toString());
  }
  const url = `${API}/goals/available/?${queryParams.toString()}`;

  const res = await axios({
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
  });

  return res;
};

export const createGoal = async (goalData:any) => {
  const res = await axios({
    url: `${API}/goals/create/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${access}`,
    },
    data: goalData
  });
  return res;
};
export const deleteGoal = async (goalId:any) => {
  const res = await axios({
    url: `${API}/goals/detail/${goalId}/`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${access}`,
    }
  });
  return res;
};
export const updateGoal = async (goalId: number, goalData: any) => {
  const res = await axios({
    url: `${API}/goals/update/${goalId}/`, 
    method: 'PUT', 
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    data: goalData, 
  });
  return res;
};