import axios from 'axios';
import { IUser } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));


export const login = async (user: IUser) => {
    const res = await axios({
        url: `${API}/users/auth/login/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*"
        },
        data: user
    })
    
    return res
} 

export const recoveryPassword = async (email: string) => {
        const res = await axios({
            url: `${API}/users/auth/reset_password/`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email: email
            }
        })
    
        return res
}

export const changePassword = async (id: number, password1: string, password2: string) => {
    try {
        const res = await axios({
            url: `${API}/users/auth/change_password/${id}/`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                password: password1,
                password2: password2,
                confirmation_code: ''
            }
        })
    
        return res
    } catch (error) {
        console.log(error, 'error');
    }
}

export const logout = async () => {
    const res = await axios({
        url: `${API}/users/auth/logout/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            refresh: refresh
        }
    })
    
    return res
}
