import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '@/widgets';
import {
    Page,
    Board,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { getCompany } from '@/entities/company/api';
import { ICompany } from '@/shared';
import { CompareArrows } from '@mui/icons-material';

const skillSystemCategories = [
    {
        id: 1,
        title: 'Моя компания',
        links: [
            {
                id: 1,
                title: 'Сотрудники',
                to: '/dashboard-hr-employees',
            },
            {
                id: 2,
                title: 'Тесты',
                to: '/dashboard-hr-tests',
            },
            {
                id: 3,
                title: 'Структура',
                to: '/dashboard-hr-structure',
            },
            {
                id: 4,
                title: 'Аналитика',
                to: '/dashboard-hr-analitic',
            },
            {
                id: 5,
                title: 'Цели компании',
                to: '/dashboard-goals-hr',
            },
        ],
    },
    {
        id: 2,
        title: 'Другое',
        links: [
            {
                id: 5,
                title: 'База должностей',
                to: '/dashboard-hr-vacancies',
            },
        ],
    },
];

const competenceSystemCategories = [
    {
        id: 1,
        title: 'Моя компания',
        links: [
            {
                id: 1,
                title: 'Сотрудники',
                to: '/dashboard-hr-employees',
            },
            {
                id: 2,
                title: 'Подразделения',
                to: '/dashboard-hr-structure',
            },
            {
                id: 3,
                title: 'Конструктор тестов и опросов',
                to: '/dashboard-hr-tests',
            },
        ],
    },
    {
        id: 2,
        title: 'Карьерное развитие',
        links: [
            {
                id: 4,
                title: 'База компетенций',
                to: '/dashboard-hr-competences'
            },
            {
                id: 5,
                title: 'База должностей',
                to: '/dashboard-hr-vacancies',
            },
            {
                id: 6,
                title: 'Аналитика компетенций',
                to: '/dashboard-hr-competence-analytics'
            },
            {
                id: 4,
                title: 'Цели компании',
                to: '/dashboard-goals-hr',
            },
        ],
    },
];

export const HrLayout: React.FC = () => {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('user'));
    const company = JSON.parse(localStorage.getItem('company'));

    useEffect(() => {
        if (!user) {
          navigate('/');
        }
    
        if (!(user?.role === 'HR manager' || user?.role === 'Super HR manager')) {
          navigate('/');
        }
    }, [])

  return (
    <Page>
        <Navbar categories={company?.competence_system ? competenceSystemCategories : skillSystemCategories} />
        <Board>
            <Outlet />
        </Board>
    </Page>
  )
}
