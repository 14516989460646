import React from 'react';
import { Top, Inputs, Bottom } from '@/app/layouts/Auth/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ButtonSolid } from '@/UI';
import { BackButton } from '@/UI';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from '@chakra-ui/react';
import { recoveryPassword } from '@/entities/user/api';
import { useNavigate } from 'react-router-dom';

interface IFormFileds {
  email: string;
}

export const RecoveryPassword: React.FC = () => {
  const navigate = useNavigate();
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError
  } = useForm<IFormFileds>();

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    recoveryPassword(data.email)
    .then((res)=>{
      if (res?.status === 200) {
        console.log(JSON.stringify(res.data))
        navigate('/');
      }
    }).catch((error)=>{
      console.log(error.response.data.error);
      console.log(error.response.status);
      if(error.response.status == 404){
        setError("email", {
          type: "client",
          message: "Ошибка авторизации, неверный Email",
        })
      }
      else if(error.response.status == 500){
        setError("email", {
          type: "client",
          message: "Сервис временно недоступен,попробуйте позже",
        })
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Top><h3>Восстановление пароля</h3></Top>
      <Inputs>
      <FormControl isInvalid={Boolean(errors.email)}>
          <FormLabel>E-mail *</FormLabel>
          <Input
            id='email'
            {...register('email', { 
              required: 'Обязательное поле'
            })}
            placeholder='example.email@gmail.com'
            type='email'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.email && <>{errors.email.message}</>}</FormErrorMessage>
        </FormControl>
      </Inputs>
      <Bottom>
        <ButtonSolid
          type='submit'
          disabled={isSubmitting ? true : false}
        >
          Отправить ссылку
        </ButtonSolid>
        <BackButton>Назад</BackButton>
      </Bottom>
    </form>
  )
}
