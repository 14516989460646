import React, { useState, useEffect } from 'react';
import { IUser } from '@/shared';
import { Card } from '@/shared';
import {
  Top,
  Title,
  CollapseButton,
} from './styles';
import {
  Button,
  Box,
} from '@chakra-ui/react';
import {
  TaskUserReviews,
  ProjectUserReviews,
} from '@/entities/projects';
import {
  getTasksByOwner,
  getTasksByContractor,
  getProjectsByOwner,
  getProjectsByContractor,
} from '@/entities/projects';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { Link } from 'react-router-dom';
import { getGoals } from '@/features/analytic';

export interface IProjectActivityProps {
  user: IUser;
}

export const ProjectActivity: React.FC<IProjectActivityProps> = ({ user }) => {
  const storageUser = JSON.parse(localStorage.getItem('user'));
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [goals, setGoals] = useState([]);
  const [tasksIsLoading, setTasksIsLoading] = useState(true);
  const [projectsIsLoading, setProjectsIsLoading] = useState(true);
  const [goalsIsLoading, setGoalsIsLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (user?.id) {
      if (user?.role === 'Manager') {
        getProjectsByOwner(user?.id)
          .then((resOwner: any) => {
            getProjectsByContractor(user?.id)
              .then((resContractor: any) => {
                setProjects([...resOwner.data, ...resContractor.data]);
                setProjectsIsLoading(false);
              })
              .catch((error: any) => {
                console.log(error);
              });
          })
          .catch((error: any) => {
            console.log(error);
          });

        getTasksByOwner(user?.id)
          .then((resOwner: any) => {
            getTasksByContractor(user?.id)
              .then((resContractor: any) => {
                setTasks([...resOwner.data, ...resContractor.data]);
                setTasksIsLoading(false);
              })
              .catch((error: any) => {
                console.log(error);
              });
          })
          .catch((error: any) => {
            console.log(error);
          });
      } else {
        getProjectsByContractor(user?.id)
          .then((res: any) => {
            setProjects([...projects, ...res.data]);
            setProjectsIsLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
          });

        getTasksByContractor(user?.id)
          .then((res: any) => {
            setTasks([...tasks, ...res.data]);
            setTasksIsLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    }
  }, [user]);

  useEffect(() => {
    // Загрузка целей пользователя
    getGoals()
      .then((res: any) => {
        const userGoals = res.data.filter((goal: any) => goal.responsible === user.id && !goal.archived);
        setGoals(userGoals);
        setGoalsIsLoading(false);
      })
      .catch((error: any) => {
        console.error('Ошибка при загрузке целей:', error);
        setGoalsIsLoading(false);
      });
  }, [storageUser.id]);

  // Функция для отрисовки прогресса цели
  const renderProgress = (goal: any) => {
    switch (goal.goal_type) {
      case 0: // Бинарная цель
        return (
          <span>
            {goal.progress === 100 ? 'Выполнено' : 'Не выполнено'}
          </span>
        );
      case 1: // Числовая цель
        return (
          <span>
            Выполнено на: <strong>{goal.progress}</strong> из <strong>{goal.target}</strong>
          </span>
        );
      case 2: // Процентная цель
        return (
          <>
            <div style={{ width: '100%', height: '8px', backgroundColor: '#e0e0e0', borderRadius: '4px' }}>
              <div style={{ width: `${goal.progress}%`, height: '100%', backgroundColor: '#466DF2', borderRadius: '4px' }} />
            </div>
            <span>{goal.progress}%</span>
          </>
        );
      case 3: // Денежная цель
        return (
          <span>
            Выполнено на: <strong>{goal.progress.toLocaleString()} ₽</strong> из <strong>{goal.target?.toLocaleString()} ₽</strong>
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      <Top>
        <Title>Цели пользователя</Title>
      </Top>

      {(tasksIsLoading || projectsIsLoading || goalsIsLoading) && (
        <Box py={6} px={4} background="var(--main-white)" borderRadius="lg">
          Загрузка...
        </Box>
      )}

      {!goalsIsLoading && goals.length === 0 && (
        <Box py={6} px={4} background="var(--main-white)" borderRadius="lg">
          Личных целей пока нет
        </Box>
      )}

      {!goalsIsLoading && goals.length > 0 && (
        <Box>
          {goals.map((goal) => (
            <Box
              key={goal.id}
              p={2}
              mb={1}
              background="var(--main-white)"
              borderRadius="lg"
              border="1px solid #e0e0e0"
            >
              <Box fontWeight="500" mb={2}>
                {goal.name}
              </Box>
              <Box>
                {renderProgress(goal)}
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {tasks.length > 0 &&
        tasks
          .slice(0, isCollapsed ? 4 : tasks.length)
          .map((task) => (
            <TaskUserReviews user={user} task={task} key={task.id} />
          ))}

      {projects.length + tasks.length > 4 && (
        <Box py={2}>
          <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <>Показать все</> : <>Скрыть</>}
            <CollapseIcon
              style={{
                transform: isCollapsed ? '' : 'rotate(180deg)',
                transition: 'all .2s ease-in-out',
              }}
              width={10}
              height={5}
            />
          </CollapseButton>
        </Box>
      )}
    </Card>
  );
};