import React, { useState, useMemo } from 'react';
import {
  Page,
  Left,
} from './styles';
import { Profile, MyCompetences,
  MySkills,
  Education,
  WorkExperience,
  ProjectActivity,

 } from '@/widgets';
import { getEmployeeById } from '@/features/employee';
import { CoursesWidget } from '@/widgets/CoursesWidget';

export const EmployeeProfile: React.FC = () => {
  const storageUser = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));
  const [user, setUser] = useState<any>();
  const [isAwaiting, setIsAwaiting] = useState(false);
  const employeeId = Number(window.location.search.substr(1).split('=')[1]);

  useMemo(() => {
    setIsAwaiting(true);
    getEmployeeById(employeeId ? employeeId : storageUser?.id)
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }, [employeeId])

  if (isAwaiting) {
    return (<>Загрузка...</>)
  }

  return (
    <>
      <Page>
        <Left>
          {company.competence_system ? 
            <MyCompetences user={user}/>
          :
            <MySkills user={user}/>
          }
          <ProjectActivity user={user} />
          <WorkExperience user={user}/>
          <Education user={user}/>
        </Left>
        <Profile user={user}/>
      </Page>
      <CoursesWidget user={user}/>
    </>
  )
}
