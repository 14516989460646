import React from 'react';
import {
  Wrapper,
  Container,
  Image,
  Info,
  Text,
  SuperHR,
  GPHLabel, // Новый компонент для надписи "ГПХ"
} from './styles';
import avatar from '@/assets/images/avatar.jpg';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from '@chakra-ui/react';
import { DeleteUserPopup } from '../DeleteUserPopup/DeleteUserPopup';
import { EditUserPopup } from '../EditUserPopup/EditUserPopup';
import { IUser, ICompany } from '@/shared';

export interface HrCardProps {
  user: IUser;
  company: ICompany;
}

export const HrCard: React.FC<HrCardProps> = ({ user, company }) => {
  const { 
    isOpen: isEditUserPopupOpen,
    onOpen: onEditUserPopupOpen,
    onClose: onEditUserPopupClose
  } = useDisclosure();
  const { 
    isOpen: isDeleteUserPopupOpen,
    onOpen: onDeleteUserPopupOpen,
    onClose: onDeleteUserPopupClose
  } = useDisclosure();

  // Проверяем, нужно ли добавить обводку, подсказку и надпись "ГПХ"
  const isSpecialUser = user?.last_name === 'sadas';
  const isSuperHR = user?.role === 'Super HR manager';

  return (
    <>
      <Popover placement='bottom-end'>
        <PopoverTrigger>
          <Wrapper>
            <Container>
              {/* Добавляем обводку и подсказку для специального пользователя */}
              <Popover isLazy placement="top">
                <PopoverTrigger>
                  <Image
                    src={user?.image ? user?.image.image : avatar}
                    alt={`${user?.last_name} ${user?.first_name[0]}.${user?.patronymic[0]}.`}
                    $isSpecialUser={isSpecialUser} // Передаем пропс для стилей
                  />
                </PopoverTrigger>
                {isSpecialUser && (
                  <PopoverContent width="auto">
                    <PopoverBody>ГПХ</PopoverBody>
                  </PopoverContent>
                )}
              </Popover>
              <Info>
                <h6>{user?.last_name} {user?.first_name[0]}.{user?.patronymic[0]}.</h6>
                <Text>{user?.active_vacancy?.title}</Text>
                {/* Отображаем надпись "Super-hr" для роли Super HR manager */}
                {isSuperHR && <SuperHR>Super-hr</SuperHR>}
                {/* Отображаем надпись "ГПХ" для специального пользователя */}
                {isSpecialUser && <GPHLabel>ГПХ</GPHLabel>}
              </Info>
            </Container>
          </Wrapper>
        </PopoverTrigger>
        <PopoverContent width={'171px'} height={'58px'}>
          <PopoverBody>
            <button onClick={onEditUserPopupOpen}>Редактировать</button>
            <button
              style={{ color: 'var(--addable-red)' }}
              onClick={onDeleteUserPopupOpen}
            >
              Удалить сотрудника
            </button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <EditUserPopup user={user} company={company} isOpen={isEditUserPopupOpen} onClose={onEditUserPopupClose} />
      <DeleteUserPopup
        userId={user.id}
        isOpen={isDeleteUserPopupOpen}
        onClose={onDeleteUserPopupClose}
      />
    </>
  );
};