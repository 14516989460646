import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './GoalManager.css';
import EmployeesAvatars from './Avatar'; // Убрано расширение .jsx
import EditGoalPopup from './popup'; // Убрано расширение .jsx
import { getInterests, getGroup } from '@/features/user'; // Добавлены импорты
import { toast } from 'react-toastify'; // Для уведомлений
import { getGoals, createGoal, updateGoal, deleteGoal } from '@/features/analytic'; // Импорт функции для удаления целей
import { getCompany } from '@/entities/company/api';
// Типы для данных
type User = {
  id: number;
  name: string;
  position: string;
};

type Goal = {
  group: any;
  responsible: any;
  id: number;
  title: string;
  progress: number;
  description?: string;
  format: 'percentage' | 'monetary' | 'numeric' | 'binary';
  createdAt?: string;
  updatedAt?: string;
  children?: Goal[];
  department?: string;
  assignee?: User;
  parent?: number | null;
  archived?: boolean; // Добавлено поле archived
};

type Department = {
  name: string;
  goals: Goal[];
  units?: {
    goals: Goal[];
  }[];
};

type ExpandedGoals = {
  [key: string]: boolean;
};

type SelectedGoalInfo = Goal & { level: number };

type GoalManagerProps = {
  department: any;
  isDepartmentHead: any;
  onAddGoal: any;
  onLinkGoal: any;
};

// Функция для получения названия уровня
const getLevelName = (level: number): string => {
  switch (level) {
    case 0:
      return 'Департамент';
    case 1:
      return 'Команда';
    case 2:
      return 'Индивидуальный';
    default:
      return 'Задача';
  }
};
// Маппинг для goalType
const goalTypeMapping: Record<number, string> = {
  0: 'Бинарная',
  1: 'Числовая',
  2: 'Процентная',
  3: 'Денежная',
};
//info-row
const GoalUser: React.FC<GoalManagerProps> = ({ department, isDepartmentHead, onAddGoal, onLinkGoal }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [expandedGoals, setExpandedGoals] = useState<ExpandedGoals>({});
  const [editingGoal, setEditingGoal] = useState<any | null>(null);
  const [isNewGoal, setIsNewGoal] = useState(false);
  const [selectedGoalInfo, setSelectedGoalInfo] = useState<any | null>(null);
  const [groupId, setGroupId] = useState<number | null>(null);
  const [groupName, setGroupName] = useState<string | null>(null);
  const [group, SetGroup] = useState<any>(null)
  const [employees, setEmployees] = useState<User[]>([]);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [viewMode, setViewMode] = useState<'department' | 'personal'>('personal');
  // Функция для генерации цвета на основе строки
  const stringToColor = (string: string): string => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, 70%, 40%)`;
  };
  const findUserById = (userId: number, employees: User[]): User | null => {
    let abc = employees.find((user) => user.id === userId) || null;
    // console.log(abc)
    return employees.find((user) => user.id === userId) || null;
  };
  // Компонент аватара пользователя
  const UserAvatar: React.FC<{ user: any }> = ({ user }) => {
    // Формируем полное имя из first_name, last_name и patronymic
    let last_name = user.name.split('')[0]
    let first_name = user.name.split('')[1]
    const fullName = `${last_name || ''} ${first_name || ''}`.trim();
  
    // Если имя пустое, используем fallback
    const name = fullName || "Unknown User";
  
    // Формируем инициалы
    const initials = name
      .split(' ') // Разделяем имя по пробелам
      .map((n) => n[0]) // Берем первую букву каждого слова
      .join('') // Объединяем буквы
      .toUpperCase(); // Переводим в верхний регистр
  
    return (
      <div className="user-avatar" style={{ backgroundColor: stringToColor(name) }}>
        {initials}
      </div>
    );
  };
  const formatDate = (dateString: string): string => {
    if (!dateString) return 'Не указана';
  
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };
  // Функция для построения иерархии целей
  const buildHierarchy = (goals: Goal[]): Goal[] => {
    const goalMap: Record<number, Goal> = {};
    const rootGoals: Goal[] = [];

    // Сначала создаем карту целей по их id
    goals.forEach(goal => {
      goalMap[goal.id] = { ...goal, children: [] };
    });

    // Затем строим иерархию
    goals.forEach(goal => {
      if (goal.parent) {
        const parent = goalMap[goal.parent];
        if (parent) {
          parent.children?.push(goalMap[goal.id]);
        }
      } else {
        rootGoals.push(goalMap[goal.id]);
      }
    });

    return rootGoals;
  };
  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  // Загрузка целей с сервера
// В useEffect где загружаются цели:
useEffect(() => {
  console.log(companyId)
  getGoals()
    .then((res: any) => {
      const goalsData = res.data;
      console.log(goalsData);

      // Преобразуем данные с сервера в структуру, которую ожидает компонент
      const transformedGoals: Goal[] = goalsData.map((goal: any) => ({
        id: goal.id,
        title: goal.name,
        progress: goal.progress || 0,
        description: goal.description,
        format: ['binary', 'percentage', 'monetary', 'numeric'][goal.goal_type] as Goal['format'],
        createdAt: goal.created_on,
        updatedAt: goal.modified,
        parent: goal.parent?.id || null,
        group: goal.group, // Добавляем поле group
        department: goal.group === 1 ? 'Маркетинг' : 'Разработка',
        assignee: goal.assignee,
        goalType: goal.goal_type,
        responsible: goal.responsible,
        archived: goal.archived,
        target: goal.target
      }));

      // Строим иерархию целей
      const hierarchicalGoals = buildHierarchy(transformedGoals);
      setGoals(hierarchicalGoals);
    })
    .catch((error: any) => console.error(error));
}, []);

  useEffect(()=>{
    const fetchData = async () => {
      const res = await getCompany(companyId);
      console.log(res.data.group)
    }
    fetchData()
  },[])
  useEffect(() => {
    
    getInterests(user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          setGroupId(res.data.group[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // Если groupId получен, загружаем данные группы
    if (groupId) {
      getGroup(groupId)
        .then((data: any) => {
          console.log(data.data.id)
          SetGroup(data.data.id)
          setGroupName(data.data.name);
          const transformedEmployees = data.data.users.map((user: any) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`,
            position: user.role,
          }));
          setEmployees(transformedEmployees);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [groupId]);

  const toggleGoal = (path: string) => {
    setExpandedGoals((prev) => ({ ...prev, [path]: !prev[path] }));
  };

  const handleShowGoalInfo = (goal: Goal, level: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedGoalInfo({ ...goal, level });
  };

  const closeGoalInfo = () => {
    setSelectedGoalInfo(null);
  };

  const closeEditPopup = () => setEditingGoal(null);

  const handleSaveGoal = async (updatedGoal: any) => {
    try {
      if (editingGoal) {
        const updatedFields: any = {
          name: updatedGoal.title,
          description: updatedGoal.description,
          progress: updatedGoal.progress,
          goal_type: updatedGoal.goalType,
          group: updatedGoal.group,
          company: updatedGoal.company,
          parent: updatedGoal.parent,
        };

        await updateGoal(editingGoal.id, updatedFields);
        toast.success('Цель успешно обновлена!');
        setEditingGoal(null);
        window.location.reload();
      }
    } catch (error) {
      console.error('Ошибка при обновлении цели:', error);
      toast.error('Ошибка при обновлении цели');
    }
  };

  const handleDeleteGoal = async (goalId: number) => {
    try {
      await deleteGoal(goalId);
      toast.success('Цель успешно удалена!');
      setEditingGoal(null);
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при удалении цели:', error);
      toast.error('Ошибка при удалении цели');
    }
  };

  const renderGoals = (goals: Goal[], path = '', level = 0) => {
    const filteredGoals = goals.filter((goal) => !goal.archived);
  
    return (
      <div className="goals-container">
        {filteredGoals.map((goal, idx) => {
          const currentPath = path ? `${path}-${idx}` : `${idx}`;
          const isExpanded = expandedGoals[currentPath];
          const hasChildren = goal.children?.length > 0;
          const backgroundColor = `rgba(240, 240, 240, ${1 - level * 0.2})`;
  
          // Фильтрация целей в зависимости от режима просмотра
          const shouldRenderGoal =
            (viewMode === 'department' && goal.group === group) || // В режиме "department" показываем цели, где group совпадает с состоянием group
            (viewMode === 'personal' && goal.responsible === user?.id); // В режиме "personal" показываем только цели пользователя
          // console.log( goal, group)
          if (!shouldRenderGoal) {
            return null; // Пропускаем отрисовку цели, если она не должна быть видна
          }
  
          // Находим пользователя по responsible, если он есть
          const responsibleUser = goal.responsible ? findUserById(goal.responsible, employees) : null;
  
          return (
            <div key={goal.id || idx}>
              <motion.div
                className="goal-card"
                style={{
                  backgroundColor,
                  cursor: hasChildren ? 'pointer' : 'default',
                }}
                onClick={() => hasChildren && toggleGoal(currentPath)}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="goal-content">
                  <div className="goal-main">
                    <div className="goal-title">
                      <span
                        style={{
                          fontWeight: level === 0 ? '600' : 'normal',
                          color: level === 0 ? '#1A237E' : '#616161',
                        }}
                      >
                        {goal.title}
                      </span>
                    </div>
  
                    <div className="goal-progress">
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{ width: `${goal.progress}%` }}
                        />
                      </div>
                      <span className="progress-text">{goal.progress}%</span>
                    </div>
                  </div>
  
                  <div className="goal-actions">
                    {/* Отображение аватара ответственного, если он есть */}
                    {responsibleUser && (
                      <div className="responsible-avatar">
                        <UserAvatar user={responsibleUser} />
                      </div>
                    )}
  
                    <button
                      className="info-button"
                      onClick={(e) => handleShowGoalInfo(goal, level, e)}
                      aria-label="Информация о цели"
                    >
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                      </svg>
                    </button>
  
                    {isDepartmentHead && (
                      <>
                        <button
                          className="icon-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingGoal(goal);
                            setIsNewGoal(false);
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                          </svg>
                        </button>
                      </>
                    )}
  
                    {hasChildren && (
                      <motion.div
                        className="expand-icon"
                        animate={{ rotate: isExpanded ? 180 : 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                        </svg>
                      </motion.div>
                    )}
                  </div>
                </div>
              </motion.div>
  
              <AnimatePresence>
                {isExpanded && hasChildren && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    style={{
                      paddingLeft: level > 0 ? '24px' : '0',
                      marginLeft: level > 0 ? '16px' : '0',
                      borderLeft: level > 0 ? '2px solid #e0e0e0' : 'none',
                    }}
                  >
                    {renderGoals(goal.children || [], currentPath, level + 1)}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="department-goals">
      <div className="department-header">
        <div className="department-info">
          <h1>{groupName}</h1>
        </div>
        <EmployeesAvatars employees={employees} />
      </div>
      <div className="section-header">
  <h2>Цели {viewMode === 'department' ? 'Отдела' : 'Личные'}</h2>
  <div className="header-controls">
    <div className="view-mode-toggle">
      <button
        className={`toggle-btn ${viewMode === 'department' ? 'active' : ''}`}
        onClick={() => setViewMode('department')}
      >
        Отдел
      </button>
      <button
        className={`toggle-btn ${viewMode === 'personal' ? 'active' : ''}`}
        onClick={() => setViewMode('personal')}
      >
        Личные
      </button>
    </div>
  </div>
</div>

      {renderGoals(goals)}

      {editingGoal && (
        <EditGoalPopup
        goal={editingGoal}
        onSave={handleSaveGoal}
        onClose={closeEditPopup}
        onDelete={handleDeleteGoal} // Передаем функцию удаления
        />
      )}

      <AnimatePresence>
        {selectedGoalInfo && (
          <motion.div
            className="info-modal-overlay"
            onClick={closeGoalInfo}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="info-modal-content"
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
            >
              <button className="modal-close-button" onClick={closeGoalInfo}>×</button>
              <h3>{selectedGoalInfo.title}</h3>
              <div className="info-modal-body">
                <div className="info-row">
                  <span className="info-label">Уровень:</span>
                  <span className="info-value">
                    {getLevelName(selectedGoalInfo.level)}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Формат:</span>
                  <span className="info-value">
                    {goalTypeMapping[selectedGoalInfo.goalType] || 'Не указан'}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Прогресс:</span>
                  <span className="info-value">
                    {selectedGoalInfo.goalType === 0 ? ( // Бинарная цель
                      selectedGoalInfo.progress === 100 ? 'Выполнено' : 'Не выполнено'
                    ) : selectedGoalInfo.goalType === 3 ? ( // Денежная цель
                      `${selectedGoalInfo.progress.toLocaleString()} ₽`
                    ) : selectedGoalInfo.goalType === 2 ? ( // Процентная цель
                      `${selectedGoalInfo.progress}%`
                    ) : (
                      // Числовая цель (или другие типы, если они есть)
                      selectedGoalInfo.progress
                    )}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Дата создания:</span>
                  <span className="info-value">
                    {formatDate(selectedGoalInfo.createdAt) || 'Не указана'}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Дата изменения:</span>
                  <span className="info-value">
                    {formatDate(selectedGoalInfo.updatedAt) || 'Не указана'}
                  </span>
                </div>

                {selectedGoalInfo.description && (
                  <div className="info-description">
                    <div className="description-label">Описание:</div>
                    <p className="description-text">
                      {selectedGoalInfo.description}
                    </p>
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GoalUser;