import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import {
    AuthLayout,
    AdminLayout,
    HrLayout,
    EmployeeLayout,
    ManagerLayout,
} from "../layouts";
import { SignIn } from "@/pages/SignIn";
import { RecoveryPassword } from "@/pages/RecoveryPassword";
import { ResetPassword } from "@/pages/ResetPassword";
import { Admin } from "@/pages/Admin";
import { Vacancies } from "@/pages/Vacancies";
import { Competences } from "@/pages/Competences";
import { Employees } from "@/pages/Employees";
import { Structure } from "@/pages/Structure";
import { EmployeeProfile } from "@/pages/EmployeeProfile";
import { Page404 } from "@/pages/404";
import { Courses } from "@/pages/Courses";
import { Career } from "@/pages/Career";
import { DashboardHRTests } from "@/pages/DashboardHRTests";
import { Analytics } from '@/pages/Analytics';
import { CompetenceAnalytics } from '@/pages/CompetenceAnalytics';
import { EmployeeTests } from '@/pages/EmployeeTests';
import { ManagerTests } from '@/pages/ManagerTests';
import { ManagerProjectsTasks } from '@/pages/ManagerProjectsTasks';
import { Projects } from '@/pages/Projects';
import { Goals } from '../../pages/Goals/Goals';
import { EmployeeProjectsTasks } from '@/pages/EmployeeProjectsTasks';

export const router = createBrowserRouter([
    {
        element: <AuthLayout />,
        children: [
            {
                path: '/',
                element: <SignIn/>
            },
            {
                path: '/RecoveryPassword',
                element: <RecoveryPassword/>
            },
            {
                path: '/ResetPassword',
                element: <ResetPassword/>
            },
        ]
    },
    {
        element: <AdminLayout />,
        children: [
            {
                path: '/dashboard-admin-companies',
                element: <Admin/>,
            },
            {
                path: '/dashboard-admin-settings',
                element: <Page404/>,
            },
        ],
    },
    {
        element: <HrLayout />,
        children: [
            {
                path: '/dashboard-hr-profile',
                element: <EmployeeProfile/>,
            },
            {
                path: '/dashboard-hr-vacancies',
                element: <Vacancies/>,
            },
            {
                path: '/dashboard-hr-employees',
                element: <Employees/>,
            },
            {
                path: '/dashboard-hr-structure',
                element: <Structure/>,
            },
            {
                path: '/dashboard-hr-tests',
                element: <DashboardHRTests/>,
            },
            {
                path: '/dashboard-hr-analitic',
                element: <Analytics/>,
            },
            {
                path: '/dashboard-hr-competence-analytics',
                element: <CompetenceAnalytics/>,
            },
            {
                path: '/dashboard-hr-competences',
                element: <Competences/>
            },
            {
                path: '/dashboard-goals-hr',
                element: <Goals />,
            }
        ],
    },
    {
        element: <EmployeeLayout />,
        children: [
            {
                path: '/dashboard-employee-profile',
                element: <EmployeeProfile/>,
            },
            {
                path: '/dashboard-employee-tasks',
                element: <EmployeeProjectsTasks/>,
            },
            {
                path: '/dashboard-employee-career',
                element: <Career/>,
            },
            {
                path: '/dashboard-employee-courses',
                element: <Courses/>,
            },
            {
                path: '/dashboard-employee-tests',
                element: <EmployeeTests/>,
            },
            {
                path: '/dashboard-employee-projects',
                element: <Projects />,
            },
            {
                path: '/dashboard-goals',
                element: <Goals />,
            }
        ],
    },
    {
        element: <ManagerLayout />,
        children: [
            {
                path: '/dashboard-manager-profile',
                element: <EmployeeProfile/>,
            },
            {
                path: '/dashboard-manager-tasks',
                element: <ManagerProjectsTasks/>,
            },
            {
                path: '/dashboard-manager-career',
                element: <Career/>,
            },
            {
                path: '/dashboard-manager-courses',
                element: <Courses/>,
            },
            {
                path: '/dashboard-manager-tests',
                element: <ManagerTests/>,
            },
            {
                path: '/dashboard-manager-projects',
                element: <Projects />,
            },
            {
                path: '/dashboard-manager-employees',
                element: <Employees/>,
            },
            {
                path: '/dashboard-manager-analitic',
                element: <Analytics/>,
            },
            {
                path: '/dashboard-manager-competence-analytics',
                element: <CompetenceAnalytics/>,
            },
            {
                path: '/dashboard-goals-manager',
                element: <Goals />,
            }
        ],
    },
]);
