import React, { useMemo, useState } from 'react';
import { 
  Container,
  User,
  Image,
  Info,
  P2,
  Categories,
  Category,
  CategoryTitle,
  LinkWrapper,
  LogoutButton,
  UserDetails,
} from './styles';
import Logout from '@/assets/icons/logout.svg';
import { EditProfilePopup } from '@/entities/user/ui/EditProfilePopup/EditProfilePopup';
import { useDisclosure } from '@chakra-ui/react';
import { logout } from '@/entities/user/api';
import { NavLink } from 'react-router-dom';
import { NotificationsPopover } from '@/features/notifications';
import avatar from '@/assets/images/avatar.jpg';
import adminAvatar from '@/assets/images/admin.jpg';
import { getEmployeeById } from '@/features/employee';

export type TLink = {
  id: number;
  title: string;
  to: string;
}

export type TCategories = {
  id: number; 
  title?: string;
  links: TLink[];
}

export interface INavbar {
  categories: TCategories[];
}

export const Navbar: React.FC<INavbar> = ({ categories }) => {
  const [user, setUser] = useState<any>();
  const [isAwaiting, setIsAwaiting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  useMemo(() => {
    setIsAwaiting(true);
    getEmployeeById(userId)
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }, [])

  function handleLogout() {
    logout()
      .then((res: any) => {
        if (res?.status === 205) {
          
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        location.reload();
      })
  }

  return (
    <>
      <Container>
        <User>
          <UserDetails onClick={onOpen}>
          {user?.image ?
              <div
                  style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '50%',
                      backgroundImage: `url(${user?.image.image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                  }}
              >
              </div>  :
              <Image src={user?.image ? user?.image.image : user?.role === 'Admin' ? adminAvatar : avatar}/>
            }
            <Info>
              <h6>{user?.first_name} {'  '} {user?.last_name}</h6>
              <P2>{user?.role}</P2>
            </Info>
          </UserDetails>
          <NotificationsPopover/>
        </User>
        <Categories>
          {categories.map((category, indx) => 
            <Category key={indx}>
              {category?.title &&
                <CategoryTitle>{category.title}</CategoryTitle>
              }
              {category.links.map(link => 
                <NavLink
                  to={link.to}
                  key={link.id}
                >
                  <LinkWrapper>
                    <h6>{link.title}</h6>
                  </LinkWrapper>
                </NavLink>
              )}
            </Category>
          )}
        </Categories>
        <LogoutButton
          onClick={handleLogout}
        >
          <Logout
            color='#466DF2'
            width={15}
            height={15}
          />
          <h6 style={{ color: 'var(--main-purple)' }}>Выход</h6>
        </LogoutButton>
      </Container>
      <EditProfilePopup user={user} isOpen={isOpen} onClose={onClose}/>
    </>
  )
}
