import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './EditGoalPopup.css'; // Импортируем стили

// Типы для данных
type Goal = {
  id?: string; // Добавляем id для родительской цели
  title: string;
  description?: string;
  department?: string;
  createdAt: string;
  goalType: number; // Тип цели: 0 (бинарный), 1 (числовой), 2 (процентный), 3 (денежный)
  progress: number | string; // Прогресс: число (0-100) или строка ("Да"/"Нет")
  company?: number; // Компания (получаем с бэка)
  group?: number; // Группа (получаем с бэка)
  parent?: string; // Родительская цель (если есть)
};

type EditGoalPopupProps = {
  goal: any;
  onSave: any;
  onClose: any;
  onDelete: any;
};

// Функция для форматирования даты
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0'); // День (двузначное число)
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц (двузначное число)
  const year = date.getFullYear(); // Год
  const hours = String(date.getHours()).padStart(2, '0'); // Часы (двузначное число)
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Минуты (двузначное число)
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const EditGoalPopup1: React.FC<EditGoalPopupProps> = ({ goal, onSave, onClose, onDelete }) => {
  const [editedGoal, setEditedGoal] = useState<Goal>({
    ...goal,
    description: goal.description || '',
    department: goal.department || '',
    goalType: goal.goalType, // Сохраняем исходный тип цели
    progress: goal.progress || 0, // По умолчанию прогресс 0
    company: goal.company || 1, // По умолчанию компания 1
    group: goal.group || 3, // По умолчанию группа 3
    parent: goal.parent || '', // По умолчанию родительская цель отсутствует
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Обрабатываем progress в зависимости от goalType
    if (name === 'progress' && editedGoal.goalType === 0) { // Бинарный тип
      setEditedGoal(prev => ({ ...prev, progress: value }));
    } else if (name === 'progress') {
      setEditedGoal(prev => ({ ...prev, progress: Number(value) }));
    } else {
      setEditedGoal(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = (e: any) => {
    e.preventDefault();

    // Формируем данные в требуемом формате
    const formattedData = {
      ...editedGoal,
      progress: editedGoal.goalType === 0 ? (editedGoal.progress === 'Да' ? 100 : 0) : Number(editedGoal.progress), // Преобразуем progress
    };

    // Вызываем onSave с отредактированной целью
    onSave(formattedData);
  };

  const handleDelete = () => {
    if (goal.id) {
      onDelete(goal.id);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="edit-goal-popup-overlay"
      onClick={onClose}
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -50, opacity: 0 }}
        className="edit-goal-popup"
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={onClose}
          className="edit-goal-popup-close-btn"
        >
          ×
        </button>
        
        <h3 className="edit-goal-popup-title">
          Редактирование цели
        </h3>

        <div className="edit-goal-popup-fields">
          {/* Название цели */}
          <div>
            <label>Название цели</label>
            <input
              type="text"
              name="title"
              value={editedGoal.title}
              onChange={handleChange}
              className="edit-goal-popup-input"
            />
          </div>

          {/* Описание */}
          <div>
            <label>Описание (до 250 символов)</label>
            <textarea
              name="description"
              value={editedGoal.description}
              onChange={handleChange}
              maxLength={250}
              className="edit-goal-popup-textarea"
            />
          </div>

          {/* Тип цели */}
          <div>
            <label>Тип цели</label>
            <select
              name="goalType"
              value={editedGoal.goalType}
              onChange={handleChange}
              className="edit-goal-popup-select"
            >
              <option value={0}>Бинарный</option>
              <option value={1}>Числовой</option>
              <option value={2}>Процентный</option>
              <option value={3}>Денежный</option>
            </select>
          </div>

          {/* Прогресс */}
          <div>
            <label>Прогресс</label>
            {editedGoal.goalType === 0 ? ( // Бинарный тип
              <select
                name="progress"
                value={editedGoal.progress}
                onChange={handleChange}
                className="edit-goal-popup-select"
              >
                <option value="Нет">Нет</option>
                <option value="Да">Да</option>
              </select>
            ) : (
              <input
                type="number"
                name="progress"
                value={editedGoal.progress}
                onChange={handleChange}
                min={0}
                max={100}
                className="edit-goal-popup-input"
              />
            )}
          </div>

          {/* Ответственный отдел */}
          <div>
            <label>Ответственный отдел</label>
            <select
              name="department"
              value={editedGoal.department}
              onChange={handleChange}
              className="edit-goal-popup-select"
            >
              <option value="">Выберите отдел</option>
              <option value="Маркетинг">Маркетинг</option>
              <option value="Разработка">Разработка</option>
              <option value="Продажи">Продажи</option>
            </select>
          </div>

          {/* Дата создания */}
          <div>
            <label>Дата создания</label>
            <input
              type="text"
              value={formatDate(goal.createdAt)} // Форматируем дату
              disabled
              className="edit-goal-popup-input"
            />
          </div>
        </div>

        <div className="edit-goal-popup-buttons">
          <button
            onClick={onClose}
            className="edit-goal-popup-button edit-goal-popup-button-cancel"
          >
            Отмена
          </button>
          <button
            onClick={handleDelete}
            className="edit-goal-popup-button edit-goal-popup-button-delete"
          >
            Удалить
          </button>
          <button
            onClick={handleSave}
            className="edit-goal-popup-button edit-goal-popup-button-save"
          >
            Сохранить изменения
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default EditGoalPopup1;