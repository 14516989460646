import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './GoalManager.css';
import EmployeesAvatars from './Avatar'; // Убрано расширение .jsx
import EditGoalPopup1 from './EditGoalPopup1'; // Убрано расширение .jsx
import { getInterests, getGroup } from '@/features/user';
import { searchEmployees } from '@/features/employee/api';
import { getGoals, createGoal, deleteGoal, updateGoal } from '@/features/analytic'; // Импорт функции для получения целей
import { getCompany } from '@/entities/company/api';
import { toast } from 'react-toastify'; // Для уведомлений

// Типы для данных
type User = {
  id: number;
  name: string;
  position: string;
};

type Goal = {
  target: any;
  responsible: any;
  subGoals: any;
  archived: any;
  id: number; // Убедитесь, что тип id - number
  title: string;
  progress: number;
  description?: string;
  format: 'percentage' | 'monetary' | 'numeric' | 'binary';
  createdAt?: string;
  updatedAt?: string;
  children?: Goal[];
  department?: string;
  assignee?: User;
  parent?: number | null; // Добавлено поле parent
  goalType: any; // Тип цели (0: binary, 1: numeric, 2: percentage, 3: monetary)
  group?:any;
  company:any
};

type Department = {
  name: string;
  goals: Goal[];
  units?: {
    goals: Goal[];
  }[];
};

type ExpandedGoals = {
  [key: string]: boolean;
};

type SelectedGoalInfo = Goal & { level: number };

type GoalManagerProps = {
  department: any;
  isDepartmentHead: any;
  onAddGoal: any;
  onLinkGoal: any;
};

// Маппинг форматов
const formatMapping: Record<string, string> = {
  percentage: 'Процентный',
  monetary: 'Денежный',
  numeric: 'Числовой',
  binary: 'Бинарный'
};

// Функция для получения названия уровня
const getLevelName = (level: number): string => {
  switch (level) {
    case 0: return 'Департамент';
    case 1: return 'Команда';
    case 2: return 'Индивидуальный';
    default: return 'Задача';
  }
};

const stringToColor = (string: string): string => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, 70%, 40%)`;
};
// Компонент UserAvatar
const UserAvatar: React.FC<{ huser: any }> = ({ huser }) => {
  // Формируем полное имя из first_name, last_name и patronymic
  const fullName = `${huser?.last_name || ''} ${huser?.first_name || ''}`.trim();
  
  // Если имя пустое, используем fallback
  const name = fullName || "Unknown User";

  // Формируем инициалы
  const initials = name
    .split(' ') // Разделяем имя по пробелам
    .map((n) => n[0]) // Берем первую букву каждого слова
    .join('') // Объединяем буквы
    .toUpperCase(); // Переводим в верхний регистр

  // URL для ссылки (можно заменить на нужный)
  const userProfileUrl = `/dashboard-manager-profile?search=${huser.id}`;

  return (
    <a
      href={userProfileUrl} // Указываем URL для перехода
      className="user-avatar-link" // Добавляем класс для стилизации
      style={{ textDecoration: 'none', color: 'inherit' }} // Убираем подчеркивание и наследуем цвет
    >
      <div className="user-avatar" style={{ backgroundColor: stringToColor(name) }}>
        {initials}
        {/* Кастомный тултип */}
        <div className="user-avatar-tooltip">
          {huser?.first_name} {huser?.last_name}
        </div>
      </div>
    </a>
  );
};

const GoalManager: React.FC<GoalManagerProps> = ({ department, isDepartmentHead, onAddGoal, onLinkGoal }) => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const [expandedGoals, setExpandedGoals] = useState<ExpandedGoals>({});
  
  const [viewMode, setViewMode] = useState<'department' | 'unit'>('unit');
  const [chosenUnit, setChosenUnit] = useState<any>(null);

  const [editingGoal, setEditingGoal] = useState<Goal | null>(null);
  const [isNewGoal, setIsNewGoal] = useState(false);
  const [selectedGoalInfo, setSelectedGoalInfo] = useState<SelectedGoalInfo | null>(null);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [employees, setEmployees] = useState<User[]>([]);
  const [subordinates, setSubordinates] = useState<Record<string, Record<string, any>>>({});
  const [goals, setGoals] = useState<Goal[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [unit, setUnit] = useState<any[]>([]);
  const [userCompany, setUserCompany] = useState<any>(null);
  const [isDepartmentManager, setIsDepartmentManager] = useState<boolean>(null);
  const [userDepartmentId, setUserDepartmentId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Добавьте это в состояние компонента
  const [userUnitId, setUserUnitId] = useState<number | null>(null);

  const findUserById = (userId: number, departments: any[]): User | null => {
    for (const department of departments) {
      // Проверяем, есть ли в департаменте отделы (children)
      if (department.children) {
        for (const child of department.children) {
          // Проверяем, есть ли в отделе пользователи (users)
          if (child.users) {
            const user = child.users.find((u: any) => u.id === userId);
            if (user) {
              return user;
            }
          }
        }
      }
    }
    return null; // Если пользователь не найден
  };

  const showGoalInfo = (goal: Goal, level: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedGoalInfo({ ...goal, level });
  };
  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const isUserInDepartment = (department: any, userId: number): boolean => {
    if (department.children) {
      return department.children.some((unit: any) => 
        unit.users?.some((user: any) => user.id === userId)
      );
    }
    return false;
  };
  // Определяем, в каком отделе и департаменте состоит пользователь
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCompany(companyId);

        console.log('Company: ', res.data);
        console.log('User:', user);

        res.data.group.forEach((dep: any) => {
          if (dep.children) {
            dep.children.forEach((unit: any) => {
              if (unit.users?.some((u: any) => u.id === user.id)) {
                setUserDepartmentId(dep.id);
                setUserUnitId(unit.id);
                setChosenUnit(unit.id);
              }
            });
          }
        });

        setIsDepartmentManager(
          res.data.group.map((group: any) => group.id).includes(groupId)
        );

        setUserCompany(res.data.group);
        const allDepartments = res.data.group.flatMap((dep: any) => dep.children || []);
        console.log('allDepartments:', allDepartments);
        setDepartments(allDepartments);

        let units: any = [];
        allDepartments.map(
          (department: any) => {
            if (department.children) units.push(...department.children);
          }
        )
        setUnit(units);

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false); // Загрузка завершена
      }
    };
  
    fetchData();
  }, [groupId]);

  useEffect(() => {
    if (isDepartmentManager) setViewMode('department');
  }, [isDepartmentManager])

  const buildHierarchy = (goals: Goal[], departments: any[]): Goal[] => {
    const goalMap: Record<number, Goal> = {};
    const rootGoals: Goal[] = [];
  
    // Создаем карту целей
    goals.forEach(goal => {
      if (!goal.archived) {
        goalMap[goal.id] = { ...goal, subGoals: [] };
      }
    });
  
    // Строим иерархию на основе parent и group
    goals.forEach(goal => {
      if (!goal.archived) {
        if (goal.parent) {
          // Если цель имеет parent, добавляем ее как подцель
          const parent = goalMap[goal.parent];
          if (parent) {
            parent.subGoals.push(goalMap[goal.id]);
          }
        } else {
          // Если цель не имеет parent, проверяем, относится ли она к отделу
          const department = departments.find(dep => 
            dep.children?.some((child: any) => child.id === goal.group)
          );
          if (department) {
            // Если цель относится к отделу, добавляем ее как корневую цель
            rootGoals.push(goalMap[goal.id]);
          } else {
            // Если цель не относится к отделу, добавляем ее как корневую
            rootGoals.push(goalMap[goal.id]);
          }
        }
      }
    });

    return rootGoals;
  };
  const handleViewModeChange = (mode: 'department' | 'unit', unit: number = -1) => {
    setChosenUnit(unit !== -1 ? unit : userUnitId);
    setViewMode(mode);
    setExpandedGoals({}); // Сброс раскрытых целей
  };
  useEffect(()=>{
    ///Департамент
    // console.log(goals)
  },[goals])
  const formatDate = (dateString: string): string => {
    if (!dateString) return 'Не указана';
  
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  useEffect(()=>{
    console.log(goals);
  },[goals]);

    const startEditingGoal = (goal: Goal, e: React.MouseEvent) => {
      e.stopPropagation();
      setEditingGoal(goal);
    };
    const saveEditedGoal = async (updatedGoal: Goal) => {
      try {
        if (!editingGoal) {
          throw new Error('Цель для обновления не найдена');
        }
    
        // Формируем данные для обновления
        const updatedFields: any = {
          name: updatedGoal.title,
          description: updatedGoal.description,
          progress: updatedGoal.progress,
          goal_type: updatedGoal.goalType, // Убедимся, что goalType передается как число
          group: updatedGoal.group,
          company: updatedGoal.company,
          parent: updatedGoal.parent,
        };
    
        // Отправляем запрос на обновление
        const response = await updateGoal(editingGoal.id, updatedFields);
        console.log('Цель успешно обновлена:', response.data);
        toast.success('Цель успешно обновлена!');
    
        // Закрываем попап
        setEditingGoal(null);
        window.location.reload(); // Перезагружаем страницу
      } catch (error) {
        console.error('Ошибка при обновлении цели:', error);
        toast.error('Ошибка при обновлении цели');
      }
    };
    // Маппинг для goalType
    const goalTypeMapping: Record<number, string> = {
      0: 'Бинарная',
      1: 'Числовая',
      2: 'Процентная',
      3: 'Денежная',
    };
  // Функция для построения иерархии целей
  const closeEditPopup = () => setEditingGoal(null);
  // Загрузка целей с сервера
  useEffect(() => {
    if (!userCompany) return; // Не загружать цели, пока userCompany не установлен
  
    const fetchGoals = async () => {
      try {
        const res = await getGoals();
        const goalsData = res.data;
        console.log(goalsData)
        const transformedGoals: Goal[] = goalsData.map((goal: any) => ({
          id: goal.id,
          title: goal.name,
          progress: goal.progress || 0,
          description: goal.description,
          format: ['binary', 'percentage', 'monetary', 'numeric'][goal.goal_type] as Goal['format'],
          createdAt: goal.created_on,
          updatedAt: goal.modified,
          parent: goal.parent?.id || null,
          department: goal.group === 1 ? 'Маркетинг' : 'Разработка',
          assignee: goal.assignee,
          goalType: goal.goal_type,
          archived: goal.archived,
          group: goal.group,
          responsible: goal.responsible,
          target: goal.target,
        }));
        const hierarchicalGoals = buildHierarchy(transformedGoals, userCompany);
        console.log(hierarchicalGoals)
        setGoals(hierarchicalGoals);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchGoals();
  }, [userCompany]);

  useEffect(() => {
    getInterests(user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          setGroupId(res.data.group[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (groupId) {
      getGroup(groupId)
        .then((response: any) => {
          setGroupName(response.data.name);
          const transformedEmployees = response.data.users.map((user: any) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`,
            position: user.role,
          }));
          const transformedSubordinates: any = {};
          response.data.children.forEach(
            (child: any) => {
              transformedSubordinates[child.name] = {
                child: child,
                subordinates: child.users?.map(
                  (user: any) => ({
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    position: user.role,
                  })
                )
              }
            });
          setEmployees(transformedEmployees);
          setSubordinates(transformedSubordinates);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [groupId]);

  const toggleGoal = (path: string) => {
    setExpandedGoals(prev => ({ ...prev, [path]: !prev[path] }));
  };

  const handleShowGoalInfo = (goal: Goal, level: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedGoalInfo({ ...goal, level });
  };

  const closeGoalInfo = () => {
    setSelectedGoalInfo(null);
  };

  const handleDeleteGoal = async (goalId: number) => {
    try {
      await deleteGoal(goalId);
      console.log('Цель успешно удалена:', goalId);
      toast.success('Цель успешно удалена!');
      setEditingGoal(null);
      window.location.reload(); // Перезагружаем страницу
    } catch (error) {
      console.error('Ошибка при удалении цели:', error);
      toast.error('Ошибка при удалении цели');
    }
  };

  // Функция для отрисовки прогресса в зависимости от типа цели
const renderProgress = (goal: Goal) => {
  switch (goal.goalType) {
    case 0: // BINARY
      return (
        <span className="goal-progress-text">
          {goal.progress === 100 ? 'Выполнено' : 'Не выполнено'}
        </span>
      );
    case 1: // PROGRESS_INTEGER
      return (
        <span className="goal-progress-text">
          Выполнено на <strong>{goal.progress}</strong> из <strong>{goal.target}</strong>
        </span>
      );
    case 2: // PROGRESS_PERCENT
      return (
        <>
          <div className="goal-progress-bar">
            <div className="goal-progress-fill" style={{ width: `${Number(goal.progress) / goal.target * 100}%` }} />
          </div>
          <span className="goal-progress-text">{goal.progress}%</span>
        </>
      );
    case 3: // PROGRESS_MONEY
      return (
        <span className="goal-progress-text">
          Выполнено на <strong>{goal.progress.toLocaleString()} ₽</strong> из <strong>{goal.target?.toLocaleString()} ₽</strong>
        </span>
      );
    default:
      return null;
  }
};

const renderGoals = (goals: Goal[] | undefined, path = "", level = -1) => {
  if (isLoading) {
    return <div className="no-goals">Загрузка данных...</div>;
  }

  if (!goals) {
    return <div className="no-goals">Загрузка целей...</div>;
  }

//   if (!goals.length && level !== -1) {
//     return <div className="no-goals">Нет целей для отображения</div>;
//   }

  // Если включен режим отдела, фильтруем цели по userUnitId
  if (viewMode === 'unit') {
    let unitGoals = goals.filter((goal) => goal.group === chosenUnit);
    for (let i = 0; i < goals.length; i++) {
      if (goals[i].subGoals) {
        for (let j = 0; j < goals[i].subGoals.length; j++) {
          if (goals[i].subGoals[j].group === chosenUnit) {
            unitGoals.push(goals[i].subGoals[j]);
          }
        }
      }
    }
    const personalGoals = goals.filter(
      (goal) =>
        goal.responsible &&
        departments
          .find((dep) => dep.children?.some((unit: any) => unit.id === chosenUnit))
          ?.children?.find((unit: any) => unit.id === userUnitId)
          ?.users?.some((user: any) => user.id === goal.responsible)
    );

    const allGoals = [...unitGoals, ...personalGoals];

    return allGoals
      .filter((goal) => !goal.archived)
      .map((goal, idx) => {
        const currentPath = `${path}-${idx}`;
        const isExpanded = expandedGoals[currentPath];
        const hasSubGoals = goal.subGoals?.length > 0;

        const isUnitLevelGoal = goal.group === groupId;
        const responsibleUser = goal.responsible ? findUserById(goal.responsible, userCompany) : null;

        return (
          <div key={goal.id || idx}>
            <motion.div
              className="goal-container"
              style={{ backgroundColor: `rgba(240, 240, 240, ${1 - level * 0.2})` }}
              onClick={() => hasSubGoals && toggleGoal(currentPath)}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="goal-header">
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "5px", flexGrow: 1 }}>
                  <span className="goal-title">{goal.title}</span>
                  {renderProgress(goal)}
                  {!hasSubGoals && goal.assignee && (() => {
                    const user = findUserById(goal.responsible, userCompany);
                    return <UserAvatar huser={user} />
                  })()}
                </div>

                <div className="goal-actions">
                  {responsibleUser && (
                    <div className="responsible-avatar">
                      <UserAvatar huser={responsibleUser} />
                    </div>
                  )}

                  <button className="info-button" onClick={(e) => showGoalInfo(goal, level, e)}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                    </svg>
                  </button>

                  {isUnitLevelGoal && (
                    <button className="goal-edit-button" onClick={(e) => startEditingGoal(goal, e)}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                      </svg>
                    </button>
                  )}

                  {hasSubGoals && (
                    <motion.div
                      className="goal-expand-icon"
                      animate={{ rotate: isExpanded ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                      </svg>
                    </motion.div>
                  )}
                </div>
              </div>

              {/* <div className="goal-info">
                {!goal.responsible && isUnitLevelGoal && (
                  <div className="goal-department">
                    Отдел: {departments.find(d => d.id === goal.group)?.name || 'Неизвестно'}
                  </div>
                )}
              </div> */}
            </motion.div>

            <AnimatePresence>
              {isExpanded && hasSubGoals && (
                <motion.div
                  className="sub-goal-container"
                  initial={{ opacity: 0, maxHeight: 0 }}
                  animate={{ opacity: 1, maxHeight: 1000 }}
                  exit={{ opacity: 0, maxHeight: 0 }}
                >
                  {renderGoals(goal.subGoals, currentPath, level + 1)}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        );
      });
  }

  // Если level === -1, это уровень департаментов (режим "Департамент")
  if (level === -1) {
    if (!userCompany) {
      return <div className="no-goals">Загрузка департаментов...</div>;
    }

    return userCompany
      .filter((dep: any) => isUserInDepartment(dep, userId) || isDepartmentManager) // Фильтруем департаменты
      .map((dep: any, idx: number) => {
        const currentPath = `${path}-${idx}`;
        const isExpanded = expandedGoals[currentPath];

        // Фильтруем только цели департамента, исключая цели отделов
        const departmentGoals = goals.filter(
          (goal) => {
            const dep_units = dep.children.map((child: any) => child.id)
            if (goal.group === dep.id || dep_units.includes(goal.group)) return goal;
          }
        );


        return (
          <div key={currentPath}>
            <motion.div
              className="department-card"
              onClick={() => toggleGoal(currentPath)}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="department-header">
                <span className="department-title">
                  <strong>Департамент:</strong> {dep.name}
                </span>
                <motion.div
                  className="department-expand-icon"
                  animate={{ rotate: isExpanded ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                  </svg>
                </motion.div>
              </div>
            </motion.div>

            <AnimatePresence>
              {isExpanded && (
                <motion.div
                  className="department-goals-container"
                  initial={{ opacity: 0, maxHeight: 0 }}
                  animate={{ opacity: 1, maxHeight: 1000 }}
                  exit={{ opacity: 0, maxHeight: 0 }}
                >
                  {departmentGoals.length > 0 ? (
                    renderGoals(departmentGoals, currentPath, level + 1)
                  ) : (
                    <div className="no-goals">Цели еще не назначены</div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        );
      });
  }

  const isManagingThisGroup = (goal: any) => {
    console.log('isManagingThisGroup:', goal);
    const unitDepartmentIds = departments.map((group: any) => {if (group.parent === groupId) return group.parent;})
    const unitIds = departments.map((group: any) => group.id);

    console.log('unitDepartmentIds:', unitDepartmentIds);
    console.log('unitIds:', unitIds);
    // const managedGroups = departments.filter(
    //   (group: any) => {
    //     const dep_units = group.children.map((child: any) => child.id)
    //     if (goal.group === group.id || dep_units.includes(goal.group)) return goal;
    //   }
    // );

    return unitDepartmentIds.includes(goal.group) || unitIds.includes(goal.group);
  }

  // Рендеринг целей для уровня отдела или подцелей
  return goals
    .filter((goal) => !goal.archived) // Фильтруем архивные цели
    .map((goal, idx) => {
      const currentPath = `${path}-${idx}`;
      const isExpanded = expandedGoals[currentPath];
      const hasSubGoals = goal.subGoals?.length > 0;

      // Проверяем, является ли цель целью уровня отдела
      const isUnitLevelGoal = goal.group === userUnitId;

      // Находим пользователя по responsible, если он есть
      const responsibleUser = goal.responsible ? findUserById(goal.responsible, userCompany) : null;

      return (
        <div key={goal.id || idx}>
          <motion.div
            className="goal-container"
            style={{ backgroundColor: `rgba(240, 240, 240, ${1 - level * 0.2})` }}
            onClick={() => hasSubGoals && toggleGoal(currentPath)}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="goal-header">
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "5px", flexGrow: 1 }}>
                <span className="goal-title">{goal.title}</span>
                {renderProgress(goal)}
                <span
                  className={!departments.find(department => department.id === goal.group) ? '' : 'sub-goal-button'}
                  style={{ color: "#757575" }}
                  onClick={() => {
                    const unit = departments.find(department => department.id === goal.group);
                    if (unit) {
                      handleViewModeChange('unit', unit.id);
                      console.log('unit:', unit);
                    }
                    else if (goal.responsible) {
                      navigate(`/dashboard-hr-profile?search=${goal.responsible}`)
                    }
                  }}
                >
                  {goal.responsible ?
                    'Личная'
                  :
                    !departments.find(department => department.id === goal.group) ?
                      'Департамент'
                    :
                      'Отдел ' + departments.find(d => d.id === goal.group)?.name
                  }
                </span>
                {!hasSubGoals && goal.assignee && (() => {
                  const user = findUserById(goal.responsible, userCompany);
                  return user ? (
                    <UserAvatar huser={user} />
                  ) : (
                    <span>Пользователь не найден</span>
                  );
                })()}
              </div>

              <div className="goal-actions">
                {/* Отображение аватара ответственного, если он есть */}
                {responsibleUser && (
                  <div className="responsible-avatar">
                    <UserAvatar huser={responsibleUser} />
                  </div>
                )}

                <button className="info-button" onClick={(e) => showGoalInfo(goal, level, e)}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                  </svg>
                </button>

                {/* Кнопка редактирования отображается для целей уровня отдела */}
                {isUnitLevelGoal || (isDepartmentManager && (isManagingThisGroup(goal))) && (
                  <button className="goal-edit-button" onClick={(e) => startEditingGoal(goal, e)}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                    </svg>
                  </button>
                )}

                {hasSubGoals && (
                  <motion.div
                    className="goal-expand-icon"
                    animate={{ rotate: isExpanded ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                    </svg>
                  </motion.div>
                )}
              </div>
            </div>

            {/* Отображение отдела и ответственного */}
            {/* <div className="goal-info">
              {!goal.responsible && isUnitLevelGoal && ( // Показываем "Отдел:" только для целей уровня отдела
                <div className="goal-department">
                  Отдел: {departments.find(d => d.id === goal.group)?.name || 'Неизвестно'}
                </div>
              )}
            </div> */}
          </motion.div>

          <AnimatePresence>
            {isExpanded && hasSubGoals && (
              <motion.div
                className="sub-goal-container"
                initial={{ opacity: 0, maxHeight: 0 }}
                animate={{ opacity: 1, maxHeight: 1000 }}
                exit={{ opacity: 0, maxHeight: 0 }}
              >
                {renderGoals(goal.subGoals, currentPath, level + 1)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
    });
};
  return (
    <div className="department-goals">
      {isLoading ? (
        <div className="loading">Загрузка данных...</div> // Индикатор загрузки
      ) : (
        <>
          <div className="department-header">
            <div className="department-info">
              <div
                className="department-management"
                style={{
                  borderBottom: isDepartmentManager ? '1px solid #e0e0e0' : 'none',
                  padding: isDepartmentManager ? '0 0 16px 0' : '0',
                }}
              >
                <div>
                  <h3
                    className="group-name"
                    onClick={() => handleViewModeChange('department')}
                  >
                      {groupName}
                    </h3>
                  <div style={{ fontSize: '12px', color: 'gray' }}>
                    {isDepartmentManager ?
                      'Департамент'
                    :
                      'Отдел'
                    }
                  </div>
                </div>
                <EmployeesAvatars employees={employees} />
              </div>
              {isDepartmentManager && 
                <div className='department-units'>
                  <div className='department-units-container'>
                    {user.role !== 'User' && Object.entries(subordinates).map(([key, values]) => 
                      <div className='department-unit' key={key}>
                        <div
                          className='unit-name'
                          onClick={() => handleViewModeChange('unit', values.child.id)}
                        >
                          {key}
                        </div>
                        {values.subordinates.length ? 
                          <EmployeesAvatars employees={values.subordinates}/>
                        :
                          <div style={{ fontSize: '12px', color: 'gray' }}>Нет сотрудников</div>
                        }
                        
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>

          </div>
          <div className="section-header">
            <h2 style={{ color: '#000' }}>Цели {viewMode === 'department' ? 'департамента' : 'отдела'}</h2>
            {user.role === 'Manager' && !isDepartmentManager &&
              <div className="header-controls">
                <button
                  className={`toggle-btn ${viewMode === 'department' ? 'active' : ''}`}
                  onClick={() => handleViewModeChange('department')}
                >
                  Департамент
                </button>
                <button
                  className={`toggle-btn ${viewMode === 'unit' ? 'active' : ''}`}
                  onClick={() => handleViewModeChange('unit')}
                >
                  Отдел
                </button>
              </div>
            }
          </div>
  
          {renderGoals(goals)}
  
          {editingGoal && (
            <EditGoalPopup1
              goal={editingGoal}
              onSave={saveEditedGoal}
              onClose={closeEditPopup}
              onDelete={handleDeleteGoal}
            />
          )}
     <AnimatePresence>
        {selectedGoalInfo && (
          <motion.div
            className="info-modal-overlay"
            onClick={closeGoalInfo}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="info-modal-content"
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
            >
              <button className="modal-close-button" onClick={closeGoalInfo}>×</button>
              <h3>{selectedGoalInfo.title}</h3>
              <div className="info-modal-body">
                <div className="info-row">
                  <span className="info-label">Уровень:</span>
                  <span className="info-value">
                    {getLevelName(selectedGoalInfo.level)}
                  </span>
                </div>
                
                <div className="info-row">
                  <span className="info-label">Формат:</span>
                  <span className="info-value">
                  {goalTypeMapping[selectedGoalInfo.goalType] || 'Не указан'}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Прогресс:</span>
                  <span className="info-value">
                  {selectedGoalInfo.goalType === 0 ? ( // Бинарная цель
                    selectedGoalInfo.progress === 100 ? 'Выполнено' : 'Не выполнено'
                  ) : selectedGoalInfo.goalType === 3 ? ( // Денежная цель
                   `${selectedGoalInfo.progress.toLocaleString()} / ${selectedGoalInfo.target}₽`
                  ) : selectedGoalInfo.goalType === 2 ? ( // Процентная цель
                   `${selectedGoalInfo.progress} / ${selectedGoalInfo.target}%`
                  ) : (
                  // Числовая цель (или другие типы, если они есть)
                  selectedGoalInfo.progress
                  )}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Дата создания:</span>
                  <span className="info-value">
                    {formatDate(selectedGoalInfo.createdAt) || 'Не указано'}
                  </span>
                </div>

                <div className="info-row">
                  <span className="info-label">Дата изменения:</span>
                  <span className="info-value">
                    {formatDate(selectedGoalInfo.updatedAt) || 'Не указано'}
                  </span>
                </div>

                {selectedGoalInfo.description && (
                  <div className="info-description">
                    <div className="description-label">Описание:</div>
                    <p className="description-text">
                      {selectedGoalInfo.description}
                    </p>
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
        </>
      )}
    </div>
  );
};

export default GoalManager;