import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './EditGoalPopup.css'; // Импортируем стили

// Типы для данных
type Goal = {
  id?: string; // Добавляем id для родительской цели
  title: string;
  description?: string;
  department?: string;
  createdAt: string;
  goalType: 'binary' | 'non-binary'; // Тип цели: бинарная или не бинарная
  progressType?: 'numeric' | 'percentage' | 'monetary'; // Тип прогресса для небинарной цели
  progress: number | string; // Прогресс: число (0-100) или строка ("Да"/"Нет")
  company?: number; // Компания (получаем с бэка)
  group?: number; // Группа (получаем с бэка)
  parent?: string; // Родительская цель (если есть)
  target?: number; // Целевое значение для числовых и денежных целей
};

type EditGoalPopupProps = {
  goal: any;
  onSave: any;
  onClose: any;
  onDelete: any; // Новый пропс для удаления цели
};

// Функция для форматирования даты
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Получаем день, месяц, год, часы и минуты
  const day = String(date.getDate()).padStart(2, '0'); // День (двузначное число)
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц (двузначное число)
  const year = date.getFullYear(); // Год
  const hours = String(date.getHours()).padStart(2, '0'); // Часы (двузначное число)
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Минуты (двузначное число)

  // Возвращаем отформатированную строку
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const EditGoalPopup: React.FC<EditGoalPopupProps> = ({ goal, onSave, onClose, onDelete }) => {
  const [editedGoal, setEditedGoal] = useState<Goal>({
    ...goal,
    description: goal.description || '',
    department: goal.department || '',
    goalType: goal.goalType === 0 ? 'binary' : 'non-binary', // Преобразуем числовой goalType в строковый
    progressType: goal.goalType === 1 ? 'numeric' : goal.goalType === 2 ? 'percentage' : 'monetary', // Тип прогресса по умолчанию
    progress: goal.progress, // По умолчанию прогресс из пропсов
    company: goal.company, // По умолчанию компания из пропсов
    group: goal.group, // По умолчанию группа из пропсов
    parent: goal.parent || '', // По умолчанию родительская цель отсутствует
    target: goal.target || 0, // По умолчанию целевое значение 0
  });
  console.log(goal)
  // Эффект для сброса progressType при изменении goalType
  useEffect(() => {
    if (editedGoal.goalType === 'binary') {
      // Если цель бинарная, сбрасываем progressType
      setEditedGoal(prev => ({ ...prev, progressType: undefined }));
    } else {
      // Если цель не бинарная, устанавливаем progressType по умолчанию
      setEditedGoal(prev => ({ ...prev, progressType: 'numeric' }));
    }
  }, [editedGoal.goalType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditedGoal(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    console.log('Inside EditGoalPopup of HR')
  }, []);

  const handleSave = (e: any) => {
    e.preventDefault();

    // Формируем данные в требуемом формате
    const formattedData = {
      name: editedGoal.title,
      description: editedGoal.description,
      goal_type: editedGoal.goalType === 'binary' ? 0 : editedGoal.progressType === 'numeric' ? 1 : editedGoal.progressType === 'percentage' ? 2 : 3, // Преобразуем обратно в числовой формат
      company: editedGoal.company, // Компания
      group: editedGoal.group, // Группа
      parent: editedGoal.parent || null, // Родительская цель
      progress: editedGoal.goalType === 'binary' ? (editedGoal.progress === "Да" ? 100 : 0) : editedGoal.progress, // Прогресс
      target: editedGoal.progressType === 'numeric' || editedGoal.progressType === 'monetary' ? editedGoal.target : null, // Целевое значение для числовых и денежных целей
    };

    // Выводим данные в консоль
    console.log('Сохраненные данные:', formattedData);

    // Вызываем onSave с отредактированной целью
    onSave(formattedData);
  };

  const handleDelete = () => {
    // Вызываем onDelete с id цели
    if (goal.id) {
      onDelete(goal.id);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="edit-goal-popup-overlay"
      onClick={onClose}
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -50, opacity: 0 }}
        className="edit-goal-popup"
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={onClose}
          className="edit-goal-popup-close-btn"
        >
          ×
        </button>
        
        <h3 className="edit-goal-popup-title">
          Редактирование цели
        </h3>

        <div className="edit-goal-popup-fields">
          {/* Название цели */}
          <div>
            <label>Название цели</label>
            <input
              type="text"
              name="title"
              value={editedGoal.title}
              onChange={handleChange}
              className="edit-goal-popup-input"
            />
          </div>

          {/* Описание */}
          <div>
            <label>Описание (до 250 символов)</label>
            <textarea
              name="description"
              value={editedGoal.description}
              onChange={handleChange}
              maxLength={250}
              className="edit-goal-popup-textarea"
            />
          </div>

          {/* Тип цели */}
          <div>
            <label>Тип цели</label>
            <select
              name="goalType"
              value={editedGoal.goalType}
              onChange={handleChange}
              className="edit-goal-popup-select"
            >
              <option value="binary">Бинарная</option>
              <option value="non-binary">Не бинарная</option>
            </select>
          </div>

          {/* Тип прогресса (для небинарной цели) */}
          {editedGoal.goalType === 'non-binary' && (
            <div>
              <label>Тип прогресса</label>
              <select
                name="progressType"
                value={editedGoal.progressType}
                onChange={handleChange}
                className="edit-goal-popup-select"
              >
                <option value="numeric">Числовая цель</option>
                <option value="percentage">В процентах</option>
                <option value="monetary">В денежном выражении</option>
              </select>
            </div>
          )}

          {/* Прогресс */}
          <div>
            <label>Прогресс</label>
            {editedGoal.goalType === 'binary' ? (
              // Бинарная цель
              <select
                name="progress"
                value={editedGoal.progress}
                onChange={handleChange}
                className="edit-goal-popup-select"
              >
                <option value="Нет">Не выполнено</option>
                <option value="Да">Выполнено</option>
              </select>
            ) : (
              // Небинарная цель
              <input
                type="number"
                name="progress"
                value={editedGoal.progress}
                onChange={handleChange}
                min={0}
                max={editedGoal.progressType === 'percentage' ? 100 : undefined} // Ограничение для процентной цели
                className="edit-goal-popup-input"
              />
            )}
          </div>

          {/* Поле "Цель" для числовых и денежных целей */}
          {editedGoal.goalType === 'non-binary' && (editedGoal.progressType === 'numeric' || editedGoal.progressType === 'monetary') && (
            <div>
              <label>Цель</label>
              <input
                type="number"
                name="target"
                value={editedGoal.target}
                onChange={handleChange}
                min={0}
                className="edit-goal-popup-input"
              />
            </div>
          )}

          {/* Дата создания */}
          <div>
            <label>Дата создания</label>
            <input
              type="text"
              value={formatDate(goal.createdAt)} // Форматируем дату
              disabled
              className="edit-goal-popup-input"
            />
          </div>
        </div>

        <div className="edit-goal-popup-buttons">
          <button
            onClick={onClose}
            className="edit-goal-popup-button edit-goal-popup-button-cancel"
          >
            Отмена
          </button>
          <button
            onClick={handleDelete}
            className="edit-goal-popup-button edit-goal-popup-button-delete"
          >
            Удалить
          </button>
          <button
            onClick={handleSave}
            className="edit-goal-popup-button edit-goal-popup-button-save"
          >
            Сохранить изменения
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default EditGoalPopup;