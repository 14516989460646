import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Checkbox,
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Select,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ICompany } from '@/shared';
import { addEmployee, getEmployees } from '@/features/employee';
import employeesState from '@/pages/Employees/store/employeesState';
import styled from 'styled-components';
import { getGroups, getDepartmentByName, getGroupByName } from '@/features/department';
import { roles } from '@/data/roles';
import { getCompany } from '@/entities/company/api';

export const PopoverButton = styled.button`
  display: block;
  font-size: 16px;
  line-height: 28px;
  width: 100%;
  text-align: left;
`;

interface IFormFileds {
    first_name: string;
    last_name: string;
    patronymic: string;
    birthday: string;
    email: string;
    company: any;
    department: string;
    role: string;
    group: any;
    vacancy: any;
    isSuperHR: boolean;
    gph: boolean
}

export interface AddHrPopupProps {
  isOpen: boolean;
  onClose: () => void;
  company: ICompany;
}

export const AddHrPopup: React.FC<AddHrPopupProps> = ({ isOpen, onClose, company }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const [userCompany, setUserCompany] = useState(company);

  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);

  const [role, setRole] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [department, setDepartment] = useState<any>();
  const [groupName, setGroupName] = useState('');
  const [group, setGroup] = useState<any>();
  const [position, setPosition] = useState<any>();
  const [gph, setGph] = useState<any>();

  const user = JSON.parse(localStorage.getItem('user'));
  const companyId = JSON.parse(
    localStorage.getItem('user')
  )?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    if (user?.role === 'Super HR manager' || user?.role === 'HR manager' && companyId) {
      getCompany(companyId)
        .then((res: any) => {
          setUserCompany(res.data);
          setDepartments(res.data.group);
        })
        .catch(e => {
          console.log(e);
        })
    }
  }, [])

  useEffect(() => {
    if (user?.role === 'Admin') {
      getCompany(userCompany?.id)
        .then((res: any) => {
          setDepartments(res.data.group);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, []);

  useEffect(() => {
    if (departmentName) {
      getDepartmentByName(departmentName)
        .then((res: any) => {
          if (res?.status === 200) {
            setDepartment(res.data[0]);
            console.log(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [departmentName])

  useEffect(() => {
    getGroupByName(groupName)
    .then((res: any) => {
      if (res?.status === 200) {
        setGroup(res.data[0]);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }, [groupName])

  useEffect(() => {
    if (department) {
      getGroups(department?.id)
        .then((res: any) => {
          console.log("Groups:", res.data);
          setGroups(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [department])

  function handleGetEmployees() {
    getEmployees(userCompany?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          employeesState.setEmployees(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  
  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    console.log(data)
    data.company = [userCompany?.id];

    if (role === 'Сотрудник') {
      data.role = 'User';
    }

    if (role === 'Hr-специалист') {
      data.role = 'HR manager';
    }

    if (role === 'Руководитель') {
      data.role = 'Manager';
    }

    if (user?.role === 'Admin' && data.isSuperHR) {
      data.role = 'Super HR manager';
    }

    if (user?.role === 'Admin' && !data.isSuperHR) {
      data.role = 'HR manager';
    }

    data.group = group ? [group?.id] : [];
    data.vacancy = position ? [Number(position)] : [];
    data.gph = gph ? true : false;
    addEmployee(data)
    .then((res: any) => {
      if (res?.status === 201) {
        handleGetEmployees();
        if (user.role === 'Admin') {
          window.location.reload();
        }
        onClose();
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Новый сотрудник</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <h5>Основная информация</h5>
              <FormControl isInvalid={Boolean(errors.first_name)}>
                <FormLabel>Имя *</FormLabel>
                <Input
                  id='first_name'
                  {...register('first_name', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Мария'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.first_name && <>{errors.first_name.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.first_name)}>
                <FormLabel>Фамилия *</FormLabel>
                <Input
                  id='last_name'
                  {...register('last_name', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Васенева'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.last_name && <>{errors.last_name.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.patronymic)}>
                <FormLabel>Отчество</FormLabel>
                <Input
                  id='patronymic'
                  {...register('patronymic')}
                  placeholder='Евгеньевна'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.patronymic && <>{errors.patronymic.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel>Почта *</FormLabel>
                <Input
                  id='email'
                  {...register('email', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='vaseneva@emp.ru'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.email && <>{errors.email.message}</>}</FormErrorMessage>
              </FormControl>

              <h5>Работа и должность</h5>
              <FormControl isInvalid={Boolean(errors.company)}>
                <FormLabel>Компания *</FormLabel>
                <Input
                  disabled
                  defaultValue={company?.name}
                  id='company'
                  {...register('company')}
                  placeholder='Эмплифлоу'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.company && <>{errors.company.message}</>}</FormErrorMessage>
              </FormControl>
              
              {user?.role !== 'Admin' &&
                <FormControl isInvalid={Boolean(errors.department)}>
                  <FormLabel>Департамент</FormLabel>
                  <Select
                    isReadOnly={false}
                    variant='flushed'
                    placeholder='Выберите отдел'
                    value={departmentName}
                    onChange={e => setDepartmentName(e.target.value)}
                  >
                    {departments.length > 0 ?
                      <>{departments.map(department =>
                        <option key={department.id}>{department?.name}</option> 
                      )}</> :
                      <>
                        <option key={Date.now()}>Загрузка...</option>
                      </>
                    } 
                  </Select>
                </FormControl>
              }

              {user?.role !== 'Admin' &&
                <FormControl isInvalid={Boolean(errors.group)}>
                  <FormLabel>Отдел</FormLabel>
                  <Select
                    isReadOnly={false}
                    variant='flushed'
                    placeholder='Выберите отдел'
                    value={groupName}
                    onChange={e => setGroupName(e.target.value)}
                  >
                    {groups.map(group =>
                      <option value={group.name} key={group.id}>{group.name}</option> 
                    )}
                  </Select>
                </FormControl>
              }

              <FormControl isInvalid={Boolean(errors.vacancy)}>
                <FormLabel>Должность *</FormLabel>
                  {user?.role === 'Admin' &&
                    <Input
                      disabled
                      defaultValue='Hr-специалист'
                      id='role'
                      {...register('role')}
                      placeholder='Hr-специалист'
                      type='text'
                      size='sm'
                      variant='flushed'
                    />
                  }
                  {(user?.role === 'HR manager' || user?.role === 'Super HR manager') &&
                    <Select
                      value={position}
                      onChange={e => setPosition(e.target.value)}
                      isReadOnly={false}
                      variant='flushed'
                      placeholder='Выберите должность'
                    >
                      {userCompany?.company_positions?.map((position: any) =>
                        <option value={position.id} key={position.id}>{position.grade} {position.title}</option> 
                      )}
                    </Select>
                  }
              </FormControl>

              <FormControl isInvalid={Boolean(errors.role)}>
                <FormLabel>Роль *</FormLabel>
                {(user?.role === 'Super HR manager') &&
                  <Select
                    isRequired
                    value={role}
                    onChange={e => setRole(e.target.value)}
                    isReadOnly={false}
                    variant='flushed'
                    placeholder='Выберите роль'
                  >
                    {roles.map(role =>
                      <option value={role.title} key={role.title}>{role.title}</option>
                    )}
                  </Select>
                }
                {(user?.role === 'HR manager') &&
                  <Select
                    isRequired
                    value={role}
                    onChange={e => setRole(e.target.value)}
                    isReadOnly={false}
                    variant='flushed'
                    placeholder='Выберите роль'
                  >
                    {roles.map(role =>
                      <option
                        disabled={role.title === 'Super HR-специалист' ? true : false}
                        value={role.title}
                        key={role.title}
                      >
                        {role.title}
                      </option> 
                    )}
                  </Select>
                }
                {user?.role === 'Admin' &&
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Input
                      disabled
                      defaultValue='Hr-специалист'
                      id='role'
                      {...register('role')}
                      placeholder='Hr-специалист'
                      type='text'
                      size='sm'
                      variant='flushed'
                    />
                    <Checkbox
                      id='isSuperHR'
                      {...register('isSuperHR')}
                      size='lg'
                    >
                      <p>Супер-HR</p>
                    </Checkbox>
                  </div>
                }
              </FormControl>
              <FormControl isInvalid={Boolean(errors.vacancy)}>
                <FormLabel>ГПХ *</FormLabel>
                  {(user?.role === 'HR manager' || user?.role === 'Super HR manager') &&
                    <Checkbox
                      value={position}
                      onChange={e => setPosition(e.target.value)}
                      isReadOnly={false}
                      variant='flushed'
                      placeholder='Выберите должность'
                    >
                      {userCompany?.company_positions?.map((position: any) =>
                        <option value={position.id} key={position.id}>{position.grade} {position.title}</option> 
                      )}
                    </Checkbox>
                  }
              </FormControl>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                isLoading={isSubmitting ? true : false}
              >
                Создать нового сотрудника
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}
